@use 'sass:map';
@use '@angular/material' as mat;

@mixin color($theme) {
    // Get the color config from the theme.
    $color-config: mat.get-color-config($theme);
  
    // Get the primary color palette from the color-config.
    $primary-palette: map.get($color-config, 'primary');
    $primary-color: mat.get-color-from-palette($primary-palette); 
  
    .sidenav-active {
        background-color: rgba($color: $primary-color, $alpha: var(--mdc-list-list-item-focus-state-layer-opacity)) !important;

        &:focus::before {
            background-color: transparent !important;
        }
        
        .mdc-list-item__primary-text {
            color: $primary-color !important;
        }
    }
}

@mixin theme($theme) {
    $color-config: mat.get-color-config($theme);
    @if $color-config != null {
        @include color($theme);
    }
}   