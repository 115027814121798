@charset "UTF-8";

@import "themes/leading-companies-theme";
@import "responsive";

html {
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    height: 100%;
    min-height: 100vh;
    width: 100%;
    box-sizing: border-box;
    margin: 0;
}

*,
*::before,
*::after {
    font-family: "Open Sans", sans-serif;
}

body {
    margin: 0;
    text-align: left;
    width: 100%;
    text-rendering: optimizeLegibility;
    font-feature-settings: "kern";
    -webkit-font-feature-settings: "kern";
}

/* total width */
::-webkit-scrollbar {
    background-color: #fff;
    width: 16px;
}

::-webkit-scrollbar-track {
    background-color: #fff;
}

::-webkit-scrollbar-thumb {
    background-color: #babac0;
    border-radius: 16px;
    border: 4px solid #fff;
}

::-webkit-scrollbar-button {
    display:none;
}

.mat-sidenav-content > *:not(router-outlet) {
    padding: 50px 20px 50px 20px;
    display: block;

    @include respond-to(xl) {
        max-width: 1024px;
        margin-left: auto;
        margin-right: auto;
    }

    @include respond-to(2xl) {
        max-width: 1280px;
    }

    @include respond-to(3xl) {
        max-width: 1536px;
    }
}


button, a {
    &:not(:last-child) {
        margin-right: 12px;
    }
}
