@import url("https://fonts.googleapis.com/css?family=Material+Icons");
@keyframes hotToastEnter {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
.hot-toast-animated-icon {
  animation: var(--hot-toast-animated-icon-animation, hotToastEnter 0.3s ease-in-out forwards);
  position: var(--hot-toast-animated-icon-position, relative);
  transform: var(--hot-toast-animated-icon-transform, scale(0));
}
@media (prefers-reduced-motion: reduce) {
  .hot-toast-animated-icon {
    animation-duration: var(--hot-toast-animated-icon-reduced-motion-animation-duration, none);
    opacity: var(--hot-toast-animated-icon-reduced-motion-opacity, 1);
    transform: var(--hot-toast-animated-icon-reduced-motion-transform, scale(1));
  }
}

@keyframes hotToastCircleAnimation {
  from {
    opacity: 0;
    transform: scale(0) rotate(45deg);
  }
  to {
    opacity: 1;
    transform: scale(1) rotate(45deg);
  }
}
@keyframes hotToastCheckmarkAnimation {
  0% {
    height: 0;
    opacity: 0;
    width: 0;
  }
  40% {
    height: 0;
    opacity: 1;
    width: 6px;
  }
  100% {
    height: 10px;
    opacity: 1;
  }
}
.hot-toast-checkmark-icon {
  animation: var(--hot-toast-checkmark-icon-animation, hotToastCircleAnimation 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards);
  animation-delay: var(--hot-toast-checkmark-icon-animation-delay, 100ms);
  background-color: var(--hot-toast-checkmark-icon-background-color, var(--check-primary, #61d345));
  border-radius: var(--hot-toast-checkmark-icon-border-radius, 10px);
  height: var(--hot-toast-checkmark-icon-height, 20px);
  opacity: var(--hot-toast-checkmark-icon-opacity, 0);
  position: var(--hot-toast-checkmark-icon-position, relative);
  transform: var(--hot-toast-checkmark-icon-transform, rotate(45deg));
  width: var(--hot-toast-checkmark-icon-width, 20px);
}
@media (prefers-reduced-motion: reduce) {
  .hot-toast-checkmark-icon {
    animation-duration: var(--hot-toast-checkmark-icon-reduced-motion-animation-duration, 0ms);
  }
}

.hot-toast-checkmark-icon::after {
  animation: var(--hot-toast-checkmark-icon-after-animation, hotToastCheckmarkAnimation 0.2s ease-out forwards);
  animation-delay: var(--hot-toast-checkmark-icon-after-animation-delay, 200ms);
  border-bottom-style: solid;
  border-bottom-width: var(--hot-toast-checkmark-icon-after-border-bottom, 2px);
  border-color: var(--hot-toast-checkmark-icon-after-border-color, var(--check-secondary, #fff));
  border-right-style: solid;
  border-right-width: var(--hot-toast-checkmark-icon-after-border-right, 2px);
  bottom: var(--hot-toast-checkmark-icon-after-bottom, 6px);
  box-sizing: var(--hot-toast-checkmark-icon-after-box-sizing, border-box);
  content: var(--hot-toast-checkmark-icon-after-content, "");
  height: var(--hot-toast-checkmark-icon-after-height, 10px);
  left: var(--hot-toast-checkmark-icon-after-left, 6px);
  opacity: var(--hot-toast-checkmark-icon-after-opacity, 0);
  position: var(--hot-toast-checkmark-icon-after-position, absolute);
  width: var(--hot-toast-checkmark-icon-after-width, 6px);
}
@media (prefers-reduced-motion: reduce) {
  .hot-toast-checkmark-icon::after {
    animation-duration: var(--hot-toast-checkmark-icon-after-reduced-motion-animation-duration, 0ms);
  }
}

@keyframes hotToastErrorCircleAnimation {
  from {
    opacity: 0;
    transform: scale(0) rotate(45deg);
  }
  to {
    opacity: 1;
    transform: scale(1) rotate(45deg);
  }
}
@keyframes hotToastFirstLineAnimation {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes hotToastSecondLineAnimation {
  from {
    opacity: 0;
    transform: scale(0) rotate(90deg);
  }
  to {
    opacity: 1;
    transform: scale(1) rotate(90deg);
  }
}
.hot-toast-error-icon {
  animation: var(--hot-toast-error-icon-animation, hotToastErrorCircleAnimation 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards);
  animation-delay: var(--hot-toast-error-icon-animation-delay, 100ms);
  background: var(--hot-toast-error-icon-background, var(--error-primary, #ff4b4b));
  border-radius: var(--hot-toast-error-icon-border-radius, 10px);
  height: var(--hot-toast-error-icon-height, 20px);
  opacity: var(--hot-toast-error-icon-opacity, 0);
  position: var(--hot-toast-error-icon-position, relative);
  transform: var(--hot-toast-error-icon-transform, rotate(45deg));
  width: var(--hot-toast-error-icon-width, 20px);
}
@media (prefers-reduced-motion: reduce) {
  .hot-toast-error-icon {
    animation-duration: var(--hot-toast-error-icon-reduced-motion-animation-duration, 0ms);
  }
}

.hot-toast-error-icon::after,
.hot-toast-error-icon::before {
  animation: var(--hot-toast-error-icon-after-before-animation, hotToastFirstLineAnimation 0.15s ease-out forwards);
  animation-delay: var(--hot-toast-error-icon-after-before-animation-delay, 150ms);
  background: var(--hot-toast-error-icon-after-before-background, var(--error-secondary, #fff));
  border-radius: var(--hot-toast-error-icon-after-before-border-radius, 3px);
  bottom: var(--hot-toast-error-icon-after-before-bottom, 9px);
  content: var(--hot-toast-error-icon-after-before-content, "");
  height: var(--hot-toast-error-icon-after-before-height, 2px);
  left: var(--hot-toast-error-icon-after-before-left, 4px);
  opacity: var(--hot-toast-error-icon-after-before-opacity, 0);
  position: var(--hot-toast-error-icon-after-before-position, absolute);
  width: var(--hot-toast-error-icon-after-before-width, 12px);
}
@media (prefers-reduced-motion: reduce) {
  .hot-toast-error-icon::after,
  .hot-toast-error-icon::before {
    animation-duration: var(--hot-toast-error-icon-after-before-reduced-motion-animation-duration, 0ms);
  }
}

.hot-toast-error-icon::before {
  animation: var(--hot-toast-error-icon-before-animation, hotToastSecondLineAnimation 0.15s ease-out forwards);
  animation-delay: var(--hot-toast-error-icon-before-animation-delay, 180ms);
  transform: var(--hot-toast-error-icon-before-transform, rotate(90deg));
}
@media (prefers-reduced-motion: reduce) {
  .hot-toast-error-icon::before {
    animation-duration: var(--hot-toast-error-icon-before-reduced-motion-animation-duration, 0ms);
  }
}

.hot-toast-bar-base {
  align-items: var(--hot-toast-align-items, center);
  background-color: var(--hot-toast-bg, #fff);
  border-radius: var(--hot-toast-border-radius, 4px);
  box-shadow: var(--hot-toast-shadow, 0 3px 10px rgba(0, 0, 0, 0.1), 0 3px 3px rgba(0, 0, 0, 0.05));
  color: var(--hot-toast-color, #363636);
  display: var(--hot-toast-display, flex);
  line-height: var(--hot-toast-line, 1.3);
  margin: var(--hot-toast-margin, 16px);
  max-width: var(--hot-toast-max-width, 350px);
  padding: var(--hot-toast-padding, 8px 10px);
  pointer-events: var(--hot-toast-pointer-events, auto);
  width: var(--hot-toast-width, fit-content);
  will-change: var(--hot-toast-will-change, transform);
}
.hot-toast-bar-base:hover, .hot-toast-bar-base:focus {
  animation-play-state: var(--hot-toast-animation-state, paused) !important;
}
@media (prefers-reduced-motion: reduce) {
  .hot-toast-bar-base {
    animation-duration: var(--hot-toast-reduced-motion-animation-duration, 10ms) !important;
  }
}

.hot-toast-message {
  color: var(--hot-toast-message-color, inherit);
  display: var(--hot-toast-message-display, flex);
  flex: var(--hot-toast-message-flex, 1);
  justify-content: var(--hot-toast-message-justify-content, center);
  margin: var(--hot-toast-message-margin, 4px 10px);
}

.hot-toast-bar-base-container {
  display: var(--hot-toast-container-display, flex);
  pointer-events: var(--hot-toast-container-pointer-events, none);
  position: var(--hot-toast-container-position, absolute);
  transition: var(--hot-toast-container-transition, transform 230ms cubic-bezier(0.21, 1.02, 0.73, 1));
}
@media (prefers-reduced-motion: reduce) {
  .hot-toast-bar-base-container {
    transition-duration: var(--hot-toast-container-reduced-motion-transition-duration, 10ms) !important;
  }
}
.hot-toast-bar-base-container.hot-toast-theme-snackbar .hot-toast-bar-base {
  background: var(--hot-toast-snackbar-bg, #323232);
  box-shadow: var(--hot-toast-snackbar-shadow, 0 3px 5px -1px rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.14), 0 1px 18px 0 rgba(0, 0, 0, 0.12));
  color: var(--hot-toast-snackbar-color, rgb(255, 255, 255));
}
.hot-toast-bar-base-container.hot-toast-theme-snackbar .hot-toast-close-btn {
  filter: var(--hot-toast-snackbar-close-btn-filter, invert(1) grayscale(100%) brightness(200%));
}

.hot-toast-close-btn {
  align-self: var(--hot-toast-close-btn-align-self, flex-start);
  background-color: var(--hot-toast-close-btn-background-color, transparent);
  background-image: var(--hot-toast-close-btn-background-image, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e"));
  background-position: var(--hot-toast-close-btn-background-position, center);
  background-repeat: var(--hot-toast-close-btn-background-repeat, no-repeat);
  background-size: var(--hot-toast-close-btn-background-size, 0.75em);
  border: var(--hot-toast-close-btn-border, 0);
  border-radius: var(--hot-toast-close-btn-border-radius, 0.25rem);
  box-sizing: var(--hot-toast-close-btn-box-sizing, content-box);
  display: var(--hot-toast-close-btn-display, flex);
  height: var(--hot-toast-close-btn-height, 0.8em);
  margin-top: var(--hot-toast-close-btn-margin-top, 0.25em);
  opacity: var(--hot-toast-close-btn-opacity, 0.5);
  padding: var(--hot-toast-close-btn-padding, 0.25em);
  width: var(--hot-toast-close-btn-width, 0.8em);
}
.hot-toast-close-btn:focus {
  box-shadow: var(--hot-toast-close-btn-box-shadow, 0 0 0 0.125rem rgba(13, 110, 253, 0.25));
  outline: var(--hot-toast-close-btn-outline, none);
}
.hot-toast-close-btn:hover, .hot-toast-close-btn:focus {
  opacity: var(--hot-toast-close-btn-opacity, 0.75);
}

.hot-toast-icon {
  align-self: var(--hot-toast-icon-align-self, flex-start);
  padding-top: var(--hot-toast-icon-padding-top, 0.25em);
}

@keyframes hotToastEnterAnimationNegative {
  0% {
    opacity: 0.5;
    transform: translate3d(0, -80px, 0) scale(0.6);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
}
@keyframes hotToastEnterAnimationPositive {
  0% {
    opacity: 0.5;
    transform: translate3d(0, 80px, 0) scale(0.6);
  }
  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(1);
  }
}
@keyframes hotToastExitAnimationPositive {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, -1px) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, 130px, -1px) scale(0.5);
  }
}
@keyframes hotToastExitAnimationNegative {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, -1px) scale(1);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, -130px, -1px) scale(0.5);
  }
}
.hot-toast-indicator-wrapper {
  align-items: var(--hot-toast-indicator-wrapper-align-items, center);
  display: var(--hot-toast-indicator-wrapper-display, flex);
  justify-content: var(--hot-toast-indicator-wrapper-justify-content, center);
  min-height: var(--hot-toast-indicator-wrapper-min-height, 20px);
  min-width: var(--hot-toast-indicator-wrapper-min-width, 20px);
  position: var(--hot-toast-indicator-wrapper-position, relative);
}

.hot-toast-status-wrapper {
  position: var(--hot-toast-status-wrapper-position, absolute);
}

@keyframes animate-info-background {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes animate-info-line {
  0% {
    height: 0;
    opacity: 0;
  }
  40% {
    height: 4.8px;
    opacity: 1;
  }
  100% {
    height: 8px;
    opacity: 1;
  }
}
.hot-toast-info-icon {
  animation: var(--hot-toast-info-icon-animation, animate-info-background 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards);
  animation-delay: var(--hot-toast-info-icon-animation-delay, 100ms);
  background-color: var(--hot-toast-info-icon-background-color, var(--info-primary, #0d6efd));
  border-radius: var(--hot-toast-info-icon-border-radius, 50%);
  display: var(--hot-toast-info-icon-display, block);
  height: var(--hot-toast-info-icon-height, 20px);
  opacity: var(--hot-toast-info-icon-opacity, 0);
  position: var(--hot-toast-info-icon-position, relative);
  transform: var(--hot-toast-info-icon-transform, scale(0));
  width: var(--hot-toast-info-icon-width, 20px);
}
.hot-toast-info-icon::after, .hot-toast-info-icon::before {
  background-color: var(--hot-toast-info-icon-after-before-background-color, var(--info-secondary, #fff));
  border-radius: var(--hot-toast-info-icon-after-before-border-radius, 3px);
  box-sizing: var(--hot-toast-info-icon-after-before-box-sizing, border-box);
  content: var(--hot-toast-info-icon-after-before-content, "");
  display: var(--hot-toast-info-icon-after-before-display, block);
  left: var(--hot-toast-info-icon-after-before-left, 8.5px);
  position: var(--hot-toast-info-icon-after-before-position, absolute);
  width: var(--hot-toast-info-icon-after-before-width, 2.5px);
}
.hot-toast-info-icon::after {
  animation: var(--hot-toast-info-icon-after-animation, animate-info-line 0.2s ease-out forwards);
  animation-delay: var(--hot-toast-info-icon-after-animation-delay, 200ms);
  height: var(--hot-toast-info-icon-after-height, 0);
  opacity: var(--hot-toast-info-icon-after-opacity, 0);
  top: var(--hot-toast-info-icon-after-bottom, 8px);
}
.hot-toast-info-icon::before {
  height: var(--hot-toast-info-icon-before-height, 2px);
  top: var(--hot-toast-info-icon-before-top, 4px);
}

@keyframes hotToastRotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.hot-toast-loader-icon {
  animation: var(--hot-toast-loader-icon-animation, hotToastRotate 1s linear infinite);
  border: var(--hot-toast-loader-icon-border, 2px solid);
  border-bottom-color: var(--hot-toast-loader-icon-border-color, #e0e0e0);
  border-left-color: var(--hot-toast-loader-icon-border-color, #e0e0e0);
  border-radius: var(--hot-toast-loader-icon-border-radius, 100%);
  border-right-color: var(--hot-toast-loader-icon-border-right-color, #616161);
  border-top-color: var(--hot-toast-loader-icon-border-color, #e0e0e0);
  box-sizing: var(--hot-toast-loader-icon-box-sizing, border-box);
  height: var(--hot-toast-loader-icon-height, 18px);
  padding-top: var(--hot-toast-loader-icon-padding-top, 2px);
  width: var(--hot-toast-loader-icon-width, 18px);
}
@media (prefers-reduced-motion: reduce) {
  .hot-toast-loader-icon {
    animation-duration: var(--hot-toast-loader-icon-reduced-motion-animation-duration, 5s);
  }
}

@keyframes animate-warn-background {
  from {
    opacity: 0;
    transform: scale(0);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}
@keyframes animate-warn-line {
  0% {
    height: 0;
    opacity: 0;
  }
  40% {
    height: 4.8px;
    opacity: 1;
  }
  100% {
    height: 8px;
    opacity: 1;
  }
}
.hot-toast-warning-icon {
  animation: var(--hot-toast-warning-icon-animation, animate-warn-background 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275) forwards);
  animation-delay: var(--hot-toast-warning-icon-animation-delay, 100ms);
  background-color: var(--hot-toast-warning-icon-background-color, var(--warn-primary, #ffab00));
  border-radius: var(--hot-toast-warning-icon-border-radius, 50%);
  display: var(--hot-toast-warning-icon-display, block);
  height: var(--hot-toast-warning-icon-height, 20px);
  opacity: var(--hot-toast-warning-icon-opacity, 0);
  position: var(--hot-toast-warning-icon-position, relative);
  transform: var(--hot-toast-warning-icon-transform, scale(0));
  width: var(--hot-toast-warning-icon-width, 20px);
}
.hot-toast-warning-icon::after, .hot-toast-warning-icon::before {
  background-color: var(--hot-toast-warning-icon-after-before-background-color, var(--warn-secondary, #fff));
  border-radius: var(--hot-toast-warning-icon-after-before-border-radius, 3px);
  box-sizing: var(--hot-toast-warning-icon-after-before-box-sizing, border-box);
  content: var(--hot-toast-warning-icon-after-before-content, "");
  display: var(--hot-toast-warning-icon-after-before-display, block);
  left: var(--hot-toast-warning-icon-after-before-left, 8.5px);
  position: var(--hot-toast-warning-icon-after-before-position, absolute);
  width: var(--hot-toast-warning-icon-after-before-width, 2.5px);
}
.hot-toast-warning-icon::after {
  animation: var(--hot-toast-warning-icon-after-animation, animate-warn-line 0.2s ease-out forwards);
  animation-delay: var(--hot-toast-warning-icon-after-animation-delay, 200ms);
  height: var(--hot-toast-warning-icon-after-height, 0);
  opacity: var(--hot-toast-warning-icon-after-opacity, 0);
  top: var(--hot-toast-warning-icon-after-top, 4px);
}
.hot-toast-warning-icon::before {
  bottom: var(--hot-toast-warning-icon-before-bottom, 4px);
  height: var(--hot-toast-warning-icon-before-height, 2px);
}

@font-face {
  font-family: sans-serif;
  font-style: normal;
  font-weight: 400;
  @import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,500&amp");
  font-display: swap;
}
.mat-ripple {
  overflow: hidden;
  position: relative;
}
.mat-ripple:not(:empty) {
  transform: translateZ(0);
}

.mat-ripple.mat-ripple-unbounded {
  overflow: visible;
}

.mat-ripple-element {
  position: absolute;
  border-radius: 50%;
  pointer-events: none;
  transition: opacity, transform 0ms cubic-bezier(0, 0, 0.2, 1);
  transform: scale3d(0, 0, 0);
}
.cdk-high-contrast-active .mat-ripple-element {
  display: none;
}

.cdk-visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  left: 0;
}
[dir=rtl] .cdk-visually-hidden {
  left: auto;
  right: 0;
}

.cdk-overlay-container, .cdk-global-overlay-wrapper {
  pointer-events: none;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.cdk-overlay-container {
  position: fixed;
  z-index: 1000;
}
.cdk-overlay-container:empty {
  display: none;
}

.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
}

.cdk-overlay-pane {
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: 1000;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  transition: opacity 400ms cubic-bezier(0.25, 0.8, 0.25, 1);
  opacity: 0;
}
.cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 1;
}
.cdk-high-contrast-active .cdk-overlay-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0.6;
}

.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.32);
}

.cdk-overlay-transparent-backdrop {
  transition: visibility 1ms linear, opacity 1ms linear;
  visibility: hidden;
  opacity: 1;
}
.cdk-overlay-transparent-backdrop.cdk-overlay-backdrop-showing {
  opacity: 0;
  visibility: visible;
}

.cdk-overlay-backdrop-noop-animation {
  transition: none;
}

.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  min-width: 1px;
  min-height: 1px;
}

.cdk-global-scrollblock {
  position: fixed;
  width: 100%;
  overflow-y: scroll;
}

textarea.cdk-textarea-autosize {
  resize: none;
}

textarea.cdk-textarea-autosize-measuring {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: auto !important;
  overflow: hidden !important;
}

textarea.cdk-textarea-autosize-measuring-firefox {
  padding: 2px 0 !important;
  box-sizing: content-box !important;
  height: 0 !important;
}

@keyframes cdk-text-field-autofill-start { /*!*/ }
@keyframes cdk-text-field-autofill-end { /*!*/ }
.cdk-text-field-autofill-monitored:-webkit-autofill {
  animation: cdk-text-field-autofill-start 0s 1ms;
}

.cdk-text-field-autofill-monitored:not(:-webkit-autofill) {
  animation: cdk-text-field-autofill-end 0s 1ms;
}

.mat-focus-indicator {
  position: relative;
}
.mat-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-focus-indicator-display, none);
  border: var(--mat-focus-indicator-border-width, 3px) var(--mat-focus-indicator-border-style, solid) var(--mat-focus-indicator-border-color, transparent);
  border-radius: var(--mat-focus-indicator-border-radius, 4px);
}
.mat-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-focus-indicator-display: block;
}

.mat-mdc-focus-indicator {
  position: relative;
}
.mat-mdc-focus-indicator::before {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  box-sizing: border-box;
  pointer-events: none;
  display: var(--mat-mdc-focus-indicator-display, none);
  border: var(--mat-mdc-focus-indicator-border-width, 3px) var(--mat-mdc-focus-indicator-border-style, solid) var(--mat-mdc-focus-indicator-border-color, transparent);
  border-radius: var(--mat-mdc-focus-indicator-border-radius, 4px);
}
.mat-mdc-focus-indicator:focus::before {
  content: "";
}

.cdk-high-contrast-active {
  --mat-mdc-focus-indicator-display: block;
}

body {
  --primary-color: #e6007e;
  --primary-lighter-color: #f8b3d8;
  --primary-darker-color: #db0061;
  --text-primary-color: white;
  --text-primary-lighter-color: rgba(0, 0, 0, 0.87);
  --text-primary-darker-color: white;
}

body {
  --accent-color: #778186;
  --accent-lighter-color: #d6d9db;
  --accent-darker-color: #5a6469;
  --text-accent-color: white;
  --text-accent-lighter-color: rgba(0, 0, 0, 0.87);
  --text-accent-darker-color: white;
}

body {
  --warn-color: #ff0000;
  --warn-lighter-color: #ffb3b3;
  --warn-darker-color: #ff0000;
  --text-warn-color: white;
  --text-warn-lighter-color: rgba(0, 0, 0, 0.87);
  --text-warn-darker-color: white;
}

.mat-ripple-element {
  background-color: rgba(0, 0, 0, 0.1);
}

html {
  --mat-option-selected-state-label-text-color: #e6007e;
  --mat-option-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-option-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-option-selected-state-layer-color: rgba(0, 0, 0, 0.04);
}

.mat-accent {
  --mat-option-selected-state-label-text-color: #778186;
}

.mat-warn {
  --mat-option-selected-state-label-text-color: red;
}

html {
  --mat-optgroup-label-text-color: rgba(0, 0, 0, 0.87);
}

.mat-pseudo-checkbox-full {
  color: rgba(0, 0, 0, 0.54);
}
.mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #b0b0b0;
}

.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #e6007e;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #e6007e;
}
.mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #778186;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #778186;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #778186;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #778186;
}
.mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: red;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: red;
}
.mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #fafafa;
}

.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #b0b0b0;
}
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #b0b0b0;
}

.mat-app-background {
  background-color: #fafafa;
  color: rgba(0, 0, 0, 0.87);
}

.mat-elevation-z0, .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z1, .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z2, .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z3, .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z4, .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z5, .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z6, .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z7, .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z8, .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z9, .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z10, .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z11, .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z12, .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z13, .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z14, .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z15, .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z16, .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z17, .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z18, .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z19, .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z20, .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z21, .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z22, .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z23, .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}

.mat-elevation-z24, .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}

.mat-theme-loaded-marker {
  display: none;
}

.mat-mdc-card {
  --mdc-elevated-card-container-color: white;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: white;
  --mdc-outlined-card-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #e6007e;
  --mdc-linear-progress-track-color: rgba(230, 0, 126, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(230, 0, 126, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(230, 0, 126, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(230, 0, 126, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(230, 0, 126, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(230, 0, 126, 0.25));
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #778186;
  --mdc-linear-progress-track-color: rgba(119, 129, 134, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(119, 129, 134, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(119, 129, 134, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(119, 129, 134, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(119, 129, 134, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(119, 129, 134, 0.25));
}
.mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: red;
  --mdc-linear-progress-track-color: rgba(255, 0, 0, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(255, 0, 0, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(255, 0, 0, 0.25));
}
@media (forced-colors: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(255, 0, 0, 0.25)'/%3E%3C/svg%3E");
  }
}
.mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(255, 0, 0, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(255, 0, 0, 0.25));
}
.mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #5f5f5f;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}

html {
  --mdc-filled-text-field-caret-color: #e6007e;
  --mdc-filled-text-field-focus-active-indicator-color: #e6007e;
  --mdc-filled-text-field-focus-label-text-color: rgba(230, 0, 126, 0.87);
  --mdc-filled-text-field-container-color: whitesmoke;
  --mdc-filled-text-field-disabled-container-color: #fafafa;
  --mdc-filled-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-filled-text-field-error-focus-label-text-color: red;
  --mdc-filled-text-field-error-label-text-color: red;
  --mdc-filled-text-field-error-caret-color: red;
  --mdc-filled-text-field-active-indicator-color: rgba(0, 0, 0, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(0, 0, 0, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(0, 0, 0, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: red;
  --mdc-filled-text-field-error-focus-active-indicator-color: red;
  --mdc-filled-text-field-error-hover-active-indicator-color: red;
  --mdc-outlined-text-field-caret-color: #e6007e;
  --mdc-outlined-text-field-focus-outline-color: #e6007e;
  --mdc-outlined-text-field-focus-label-text-color: rgba(230, 0, 126, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(0, 0, 0, 0.6);
  --mdc-outlined-text-field-error-caret-color: red;
  --mdc-outlined-text-field-error-focus-label-text-color: red;
  --mdc-outlined-text-field-error-label-text-color: red;
  --mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(0, 0, 0, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(0, 0, 0, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: red;
  --mdc-outlined-text-field-error-hover-outline-color: red;
  --mdc-outlined-text-field-error-outline-color: red;
  --mat-form-field-disabled-input-text-placeholder-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field-error {
  color: var(--mdc-theme-error, red);
}

.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mat-form-field-subscript-text-font);
  line-height: var(--mat-form-field-subscript-text-line-height);
  font-size: var(--mat-form-field-subscript-text-size);
  letter-spacing: var(--mat-form-field-subscript-text-tracking);
  font-weight: var(--mat-form-field-subscript-text-weight);
}

.mat-mdc-form-field-focus-overlay {
  background-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.04;
}

.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.12;
}

.mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.54);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(230, 0, 126, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(119, 129, 134, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(255, 0, 0, 0.87);
}
.mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #778186;
  --mdc-filled-text-field-focus-active-indicator-color: #778186;
  --mdc-filled-text-field-focus-label-text-color: rgba(119, 129, 134, 0.87);
  --mdc-outlined-text-field-caret-color: #778186;
  --mdc-outlined-text-field-focus-outline-color: #778186;
  --mdc-outlined-text-field-focus-label-text-color: rgba(119, 129, 134, 0.87);
}

.mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: red;
  --mdc-filled-text-field-focus-active-indicator-color: red;
  --mdc-filled-text-field-focus-label-text-color: rgba(255, 0, 0, 0.87);
  --mdc-outlined-text-field-caret-color: red;
  --mdc-outlined-text-field-focus-outline-color: red;
  --mdc-outlined-text-field-focus-label-text-color: rgba(255, 0, 0, 0.87);
}

.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}

[dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}

.mat-mdc-form-field-infix {
  min-height: 56px;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 28px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -34.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

.mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 8px;
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}

html {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(230, 0, 126, 0.87);
  --mat-select-invalid-arrow-color: rgba(255, 0, 0, 0.87);
}
html .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(119, 129, 134, 0.87);
  --mat-select-invalid-arrow-color: rgba(255, 0, 0, 0.87);
}
html .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: white;
  --mat-select-enabled-trigger-text-color: rgba(0, 0, 0, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(0, 0, 0, 0.38);
  --mat-select-placeholder-text-color: rgba(0, 0, 0, 0.6);
  --mat-select-enabled-arrow-color: rgba(0, 0, 0, 0.54);
  --mat-select-disabled-arrow-color: rgba(0, 0, 0, 0.38);
  --mat-select-focused-arrow-color: rgba(255, 0, 0, 0.87);
  --mat-select-invalid-arrow-color: rgba(255, 0, 0, 0.87);
}

html {
  --mat-autocomplete-background-color: white;
}

.mat-mdc-dialog-container {
  --mdc-dialog-container-color: white;
  --mdc-dialog-subhead-color: rgba(0, 0, 0, 0.87);
  --mdc-dialog-supporting-text-color: rgba(0, 0, 0, 0.6);
}

.mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #212121;
  --mdc-chip-elevated-container-color: #e0e0e0;
  --mdc-chip-elevated-disabled-container-color: #e0e0e0;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #212121;
  --mdc-chip-with-icon-icon-color: #212121;
  --mdc-chip-with-icon-disabled-icon-color: #212121;
  --mdc-chip-with-icon-selected-icon-color: #212121;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #212121;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #212121;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #e6007e;
  --mdc-chip-elevated-disabled-container-color: #e6007e;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #778186;
  --mdc-chip-elevated-disabled-container-color: #778186;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: red;
  --mdc-chip-elevated-disabled-container-color: red;
  --mdc-chip-focus-state-layer-color: black;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}

.mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}

.mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: #424242;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-track-color: #424242;
  --mdc-switch-disabled-unselected-handle-color: #424242;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-disabled-unselected-track-color: #424242;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-unselected-focus-handle-color: #212121;
  --mdc-switch-unselected-focus-state-layer-color: #424242;
  --mdc-switch-unselected-focus-track-color: #e0e0e0;
  --mdc-switch-unselected-handle-color: #616161;
  --mdc-switch-unselected-hover-handle-color: #212121;
  --mdc-switch-unselected-hover-state-layer-color: #424242;
  --mdc-switch-unselected-hover-track-color: #e0e0e0;
  --mdc-switch-unselected-icon-color: #fff;
  --mdc-switch-unselected-pressed-handle-color: #212121;
  --mdc-switch-unselected-pressed-state-layer-color: #424242;
  --mdc-switch-unselected-pressed-track-color: #e0e0e0;
  --mdc-switch-unselected-track-color: #e0e0e0;
}
.mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}

.mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}

.mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #e6007e;
  --mdc-radio-selected-hover-icon-color: #e6007e;
  --mdc-radio-selected-icon-color: #e6007e;
  --mdc-radio-selected-pressed-icon-color: #e6007e;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #e6007e;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #778186;
  --mdc-radio-selected-hover-icon-color: #778186;
  --mdc-radio-selected-icon-color: #778186;
  --mdc-radio-selected-pressed-icon-color: #778186;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: #778186;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: red;
  --mdc-radio-selected-hover-icon-color: red;
  --mdc-radio-selected-icon-color: red;
  --mdc-radio-selected-pressed-icon-color: red;
  --mat-radio-ripple-color: #000;
  --mat-radio-checked-ripple-color: red;
  --mat-radio-disabled-label-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-radio-button .mdc-radio {
  --mdc-radio-state-layer-size: 40px;
}

.mat-mdc-slider {
  --mdc-slider-label-container-color: black;
  --mdc-slider-label-label-text-color: white;
  --mdc-slider-disabled-handle-color: #000;
  --mdc-slider-disabled-active-track-color: #000;
  --mdc-slider-disabled-inactive-track-color: #000;
  --mdc-slider-with-tick-marks-disabled-container-color: #000;
  --mat-mdc-slider-value-indicator-opacity: 0.6;
}
.mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #e6007e;
  --mdc-slider-focus-handle-color: #e6007e;
  --mdc-slider-hover-handle-color: #e6007e;
  --mdc-slider-active-track-color: #e6007e;
  --mdc-slider-inactive-track-color: #e6007e;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #e6007e;
  --mat-mdc-slider-ripple-color: #e6007e;
  --mat-mdc-slider-hover-ripple-color: rgba(230, 0, 126, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(230, 0, 126, 0.2);
}
.mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #778186;
  --mdc-slider-focus-handle-color: #778186;
  --mdc-slider-hover-handle-color: #778186;
  --mdc-slider-active-track-color: #778186;
  --mdc-slider-inactive-track-color: #778186;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #778186;
  --mat-mdc-slider-ripple-color: #778186;
  --mat-mdc-slider-hover-ripple-color: rgba(119, 129, 134, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(119, 129, 134, 0.2);
}
.mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: red;
  --mdc-slider-focus-handle-color: red;
  --mdc-slider-hover-handle-color: red;
  --mdc-slider-active-track-color: red;
  --mdc-slider-inactive-track-color: red;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: red;
  --mat-mdc-slider-ripple-color: red;
  --mat-mdc-slider-hover-ripple-color: rgba(255, 0, 0, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(255, 0, 0, 0.2);
}

html {
  --mat-menu-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-icon-color: rgba(0, 0, 0, 0.87);
  --mat-menu-item-hover-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-item-focus-state-layer-color: rgba(0, 0, 0, 0.04);
  --mat-menu-container-color: white;
}

.mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-supporting-text-color: rgba(0, 0, 0, 0.54);
  --mdc-list-list-item-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-disabled-label-text-color: black;
  --mdc-list-list-item-disabled-leading-icon-color: black;
  --mdc-list-list-item-disabled-trailing-icon-color: black;
  --mdc-list-list-item-hover-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-leading-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-list-list-item-focus-label-text-color: rgba(0, 0, 0, 0.87);
  --mdc-list-list-item-hover-state-layer-color: black;
  --mdc-list-list-item-hover-state-layer-opacity: 0.04;
  --mdc-list-list-item-focus-state-layer-color: black;
  --mdc-list-list-item-focus-state-layer-opacity: 0.12;
}

.mdc-list-item__start,
.mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #e6007e;
  --mdc-radio-selected-hover-icon-color: #e6007e;
  --mdc-radio-selected-icon-color: #e6007e;
  --mdc-radio-selected-pressed-icon-color: #e6007e;
}

.mat-accent .mdc-list-item__start,
.mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: #778186;
  --mdc-radio-selected-hover-icon-color: #778186;
  --mdc-radio-selected-icon-color: #778186;
  --mdc-radio-selected-pressed-icon-color: #778186;
}

.mat-warn .mdc-list-item__start,
.mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #000;
  --mdc-radio-disabled-unselected-icon-color: #000;
  --mdc-radio-unselected-hover-icon-color: #212121;
  --mdc-radio-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-radio-selected-focus-icon-color: red;
  --mdc-radio-selected-hover-icon-color: red;
  --mdc-radio-selected-icon-color: red;
  --mdc-radio-selected-pressed-icon-color: red;
}

.mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #e6007e;
  --mdc-checkbox-selected-hover-icon-color: #e6007e;
  --mdc-checkbox-selected-icon-color: #e6007e;
  --mdc-checkbox-selected-pressed-icon-color: #e6007e;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #e6007e;
  --mdc-checkbox-selected-hover-state-layer-color: #e6007e;
  --mdc-checkbox-selected-pressed-state-layer-color: #e6007e;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #778186;
  --mdc-checkbox-selected-hover-icon-color: #778186;
  --mdc-checkbox-selected-icon-color: #778186;
  --mdc-checkbox-selected-pressed-icon-color: #778186;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #778186;
  --mdc-checkbox-selected-hover-state-layer-color: #778186;
  --mdc-checkbox-selected-pressed-state-layer-color: #778186;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: red;
  --mdc-checkbox-selected-hover-icon-color: red;
  --mdc-checkbox-selected-icon-color: red;
  --mdc-checkbox-selected-pressed-icon-color: red;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: red;
  --mdc-checkbox-selected-hover-state-layer-color: red;
  --mdc-checkbox-selected-pressed-state-layer-color: red;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #e6007e;
}
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #e6007e;
}

.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}

.mat-mdc-list-base {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
}

.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}

html {
  --mat-paginator-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-paginator-container-background-color: white;
  --mat-paginator-enabled-icon-color: rgba(0, 0, 0, 0.54);
  --mat-paginator-disabled-icon-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-paginator-container-size: 56px;
}

.mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}

.mat-mdc-tab-group, .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #e6007e;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #e6007e;
  --mat-tab-header-active-ripple-color: #e6007e;
  --mat-tab-header-inactive-ripple-color: #e6007e;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #e6007e;
  --mat-tab-header-active-hover-label-text-color: #e6007e;
  --mat-tab-header-active-focus-indicator-color: #e6007e;
  --mat-tab-header-active-hover-indicator-color: #e6007e;
}
.mat-mdc-tab-group.mat-accent, .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #778186;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: #778186;
  --mat-tab-header-active-ripple-color: #778186;
  --mat-tab-header-inactive-ripple-color: #778186;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: #778186;
  --mat-tab-header-active-hover-label-text-color: #778186;
  --mat-tab-header-active-focus-indicator-color: #778186;
  --mat-tab-header-active-hover-indicator-color: #778186;
}
.mat-mdc-tab-group.mat-warn, .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: red;
  --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
  --mat-tab-header-pagination-icon-color: #000;
  --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-label-text-color: red;
  --mat-tab-header-active-ripple-color: red;
  --mat-tab-header-inactive-ripple-color: red;
  --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
  --mat-tab-header-active-focus-label-text-color: red;
  --mat-tab-header-active-hover-label-text-color: red;
  --mat-tab-header-active-focus-indicator-color: red;
  --mat-tab-header-active-hover-indicator-color: red;
}
.mat-mdc-tab-group.mat-background-primary, .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #e6007e;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-accent, .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #778186;
  --mat-tab-header-with-background-foreground-color: white;
}
.mat-mdc-tab-group.mat-background-warn, .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: red;
  --mat-tab-header-with-background-foreground-color: white;
}

.mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}

html {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #778186;
  --mdc-checkbox-selected-hover-icon-color: #778186;
  --mdc-checkbox-selected-icon-color: #778186;
  --mdc-checkbox-selected-pressed-icon-color: #778186;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #778186;
  --mdc-checkbox-selected-hover-state-layer-color: #778186;
  --mdc-checkbox-selected-pressed-state-layer-color: #778186;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #e6007e;
  --mdc-checkbox-selected-hover-icon-color: #e6007e;
  --mdc-checkbox-selected-icon-color: #e6007e;
  --mdc-checkbox-selected-pressed-icon-color: #e6007e;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #e6007e;
  --mdc-checkbox-selected-hover-state-layer-color: #e6007e;
  --mdc-checkbox-selected-pressed-state-layer-color: #e6007e;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: red;
  --mdc-checkbox-selected-hover-icon-color: red;
  --mdc-checkbox-selected-icon-color: red;
  --mdc-checkbox-selected-pressed-icon-color: red;
  --mdc-checkbox-unselected-focus-icon-color: #212121;
  --mdc-checkbox-unselected-hover-icon-color: #212121;
  --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: red;
  --mdc-checkbox-selected-hover-state-layer-color: red;
  --mdc-checkbox-selected-pressed-state-layer-color: red;
  --mdc-checkbox-unselected-focus-state-layer-color: black;
  --mdc-checkbox-unselected-hover-state-layer-color: black;
  --mdc-checkbox-unselected-pressed-state-layer-color: black;
}
.mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
}
.mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(0, 0, 0, 0.38);
}

html {
  --mdc-checkbox-state-layer-size: 40px;
}

.mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #000;
}
.mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #e6007e;
}
.mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #778186;
}
.mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: red;
}
.mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-text-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #fff;
  --mdc-filled-button-label-text-color: #000;
}
.mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #e6007e;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #778186;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: red;
  --mdc-filled-button-label-text-color: #fff;
}
.mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-filled-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-filled-button-label-text-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #fff;
  --mdc-protected-button-label-text-color: #000;
}
.mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #e6007e;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #778186;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: red;
  --mdc-protected-button-label-text-color: #fff;
}
.mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-color: rgba(0, 0, 0, 0.12);
  --mdc-protected-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-protected-button-container-elevation: 0;
}

.mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
}
.mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #000;
}
.mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #e6007e;
}
.mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #778186;
}
.mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: red;
}
.mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-disabled-label-text-color: rgba(0, 0, 0, 0.38);
  --mdc-outlined-button-outline-color: rgba(0, 0, 0, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(0, 0, 0, 0.12);
}

.mat-mdc-button, .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-button.mat-primary, .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #e6007e;
  --mat-mdc-button-ripple-color: rgba(230, 0, 126, 0.1);
}
.mat-mdc-button.mat-accent, .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #778186;
  --mat-mdc-button-ripple-color: rgba(119, 129, 134, 0.1);
}
.mat-mdc-button.mat-warn, .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: red;
  --mat-mdc-button-ripple-color: rgba(255, 0, 0, 0.1);
}

.mat-mdc-raised-button, .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-raised-button.mat-primary, .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-accent, .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-raised-button.mat-warn, .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}

.mat-mdc-button.mat-mdc-button-base,
.mat-mdc-raised-button.mat-mdc-button-base,
.mat-mdc-unelevated-button.mat-mdc-button-base,
.mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}

.mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #e6007e;
  --mat-mdc-button-persistent-ripple-color: #e6007e;
  --mat-mdc-button-ripple-color: rgba(230, 0, 126, 0.1);
}
.mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #778186;
  --mat-mdc-button-persistent-ripple-color: #778186;
  --mat-mdc-button-ripple-color: rgba(119, 129, 134, 0.1);
}
.mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: red;
  --mat-mdc-button-persistent-ripple-color: red;
  --mat-mdc-button-ripple-color: rgba(255, 0, 0, 0.1);
}
.mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(0, 0, 0, 0.38);
  --mdc-icon-button-disabled-icon-color: rgba(0, 0, 0, 0.38);
}

.mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}

.mat-mdc-fab,
.mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.04;
}
.mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.12;
}
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab.mat-warn,
.mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.mat-mdc-fab[disabled][disabled],
.mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(0, 0, 0, 0.12);
  --mdc-fab-icon-color: rgba(0, 0, 0, 0.38);
  --mat-mdc-fab-color: rgba(0, 0, 0, 0.38);
}
.mat-mdc-fab.mat-unthemed,
.mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: white;
  --mdc-fab-icon-color: black;
  --mat-mdc-fab-color: #000;
}
.mat-mdc-fab.mat-primary,
.mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #e6007e;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab.mat-accent,
.mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #778186;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.mat-mdc-fab.mat-warn,
.mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: red;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}

.mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #333333;
  --mdc-snackbar-supporting-text-color: rgba(255, 255, 255, 0.87);
  --mat-snack-bar-button-color: #778186;
}

html {
  --mat-table-background-color: white;
  --mat-table-header-headline-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-table-row-item-outline-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #e6007e;
}
.mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #778186;
}
.mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: red;
}

.mat-badge {
  position: relative;
}
.mat-badge.mat-badge {
  overflow: visible;
}

.mat-badge-content {
  position: absolute;
  text-align: center;
  display: inline-block;
  border-radius: 50%;
  transition: transform 200ms ease-in-out;
  transform: scale(0.6);
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  pointer-events: none;
  background-color: var(--mat-badge-background-color);
  color: var(--mat-badge-text-color);
  font-family: Roboto, sans-serif;
  /* @alternate */
  font-family: var(--mat-badge-text-font, Roboto, sans-serif);
  font-size: 12px;
  /* @alternate */
  font-size: var(--mat-badge-text-size, 12px);
  font-weight: 600;
  /* @alternate */
  font-weight: var(--mat-badge-text-weight, 600);
}
.cdk-high-contrast-active .mat-badge-content {
  outline: solid 1px;
  border-radius: 0;
}

.mat-badge-disabled .mat-badge-content {
  background-color: var(--mat-badge-disabled-state-background-color);
  color: var(--mat-badge-disabled-state-text-color);
}

.mat-badge-hidden .mat-badge-content {
  display: none;
}

.ng-animate-disabled .mat-badge-content,
.mat-badge-content._mat-animation-noopable {
  transition: none;
}

.mat-badge-content.mat-badge-active {
  transform: none;
}

.mat-badge-small .mat-badge-content {
  width: 16px;
  height: 16px;
  line-height: 16px;
  font-size: 9px;
  /* @alternate */
  font-size: var(--mat-badge-small-size-text-size, 9px);
}
.mat-badge-small.mat-badge-above .mat-badge-content {
  top: -8px;
}
.mat-badge-small.mat-badge-below .mat-badge-content {
  bottom: -8px;
}
.mat-badge-small.mat-badge-before .mat-badge-content {
  left: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-before .mat-badge-content {
  left: auto;
  right: -16px;
}
.mat-badge-small.mat-badge-after .mat-badge-content {
  right: -16px;
}
[dir=rtl] .mat-badge-small.mat-badge-after .mat-badge-content {
  right: auto;
  left: -16px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -8px;
}
.mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -8px;
}
[dir=rtl] .mat-badge-small.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -8px;
}

.mat-badge-medium .mat-badge-content {
  width: 22px;
  height: 22px;
  line-height: 22px;
}
.mat-badge-medium.mat-badge-above .mat-badge-content {
  top: -11px;
}
.mat-badge-medium.mat-badge-below .mat-badge-content {
  bottom: -11px;
}
.mat-badge-medium.mat-badge-before .mat-badge-content {
  left: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-before .mat-badge-content {
  left: auto;
  right: -22px;
}
.mat-badge-medium.mat-badge-after .mat-badge-content {
  right: -22px;
}
[dir=rtl] .mat-badge-medium.mat-badge-after .mat-badge-content {
  right: auto;
  left: -22px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -11px;
}
.mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -11px;
}
[dir=rtl] .mat-badge-medium.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -11px;
}

.mat-badge-large .mat-badge-content {
  width: 28px;
  height: 28px;
  line-height: 28px;
  font-size: 24px;
  /* @alternate */
  font-size: var(--mat-badge-large-size-text-size, 24px);
}
.mat-badge-large.mat-badge-above .mat-badge-content {
  top: -14px;
}
.mat-badge-large.mat-badge-below .mat-badge-content {
  bottom: -14px;
}
.mat-badge-large.mat-badge-before .mat-badge-content {
  left: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-before .mat-badge-content {
  left: auto;
  right: -28px;
}
.mat-badge-large.mat-badge-after .mat-badge-content {
  right: -28px;
}
[dir=rtl] .mat-badge-large.mat-badge-after .mat-badge-content {
  right: auto;
  left: -28px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-before .mat-badge-content {
  left: auto;
  right: -14px;
}
.mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: -14px;
}
[dir=rtl] .mat-badge-large.mat-badge-overlap.mat-badge-after .mat-badge-content {
  right: auto;
  left: -14px;
}

html {
  --mat-badge-background-color: #e6007e;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #c9c8c8;
  --mat-badge-disabled-state-text-color: rgba(0, 0, 0, 0.38);
}

.mat-badge-accent {
  --mat-badge-background-color: #778186;
  --mat-badge-text-color: white;
}

.mat-badge-warn {
  --mat-badge-background-color: red;
  --mat-badge-text-color: white;
}

html {
  --mat-bottom-sheet-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-bottom-sheet-container-background-color: white;
}

html {
  --mat-legacy-button-toggle-text-color: rgba(0, 0, 0, 0.38);
  --mat-legacy-button-toggle-state-layer-color: rgba(0, 0, 0, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.54);
  --mat-legacy-button-toggle-selected-state-background-color: #c7c7c7;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(60, 59, 59, 0.26);
  --mat-legacy-button-toggle-disabled-state-background-color: #e1e1e1;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #aeaeae;
  --mat-standard-button-toggle-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-background-color: white;
  --mat-standard-button-toggle-state-layer-color: black;
  --mat-standard-button-toggle-selected-state-background-color: #c7c7c7;
  --mat-standard-button-toggle-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-state-text-color: rgba(60, 59, 59, 0.26);
  --mat-standard-button-toggle-disabled-state-background-color: white;
  --mat-standard-button-toggle-disabled-selected-state-text-color: rgba(0, 0, 0, 0.87);
  --mat-standard-button-toggle-disabled-selected-state-background-color: #aeaeae;
  --mat-standard-button-toggle-divider-color: #e0e0e0;
}

html {
  --mat-standard-button-toggle-height: 48px;
}

html {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #e6007e;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(230, 0, 126, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(230, 0, 126, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(230, 0, 126, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #e6007e;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(230, 0, 126, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-body-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-period-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-navigation-button-icon-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-header-divider-color: rgba(0, 0, 0, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(0, 0, 0, 0.54);
  --mat-datepicker-calendar-date-today-outline-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(0, 0, 0, 0.18);
  --mat-datepicker-calendar-date-text-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(0, 0, 0, 0.24);
  --mat-datepicker-range-input-separator-color: rgba(0, 0, 0, 0.87);
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(0, 0, 0, 0.38);
  --mat-datepicker-calendar-container-background-color: white;
  --mat-datepicker-calendar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #778186;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(119, 129, 134, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(119, 129, 134, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(119, 129, 134, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(119, 129, 134, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: red;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(255, 0, 0, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(255, 0, 0, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(255, 0, 0, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(255, 0, 0, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}

.mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #778186;
}
.mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: red;
}

.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
.mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}

html {
  --mat-divider-color: rgba(0, 0, 0, 0.12);
}

html {
  --mat-expansion-container-background-color: white;
  --mat-expansion-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-actions-divider-color: rgba(0, 0, 0, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(44, 44, 44, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(44, 44, 44, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(60, 59, 59, 0.26);
  --mat-expansion-header-text-color: rgba(0, 0, 0, 0.87);
  --mat-expansion-header-description-color: rgba(0, 0, 0, 0.54);
  --mat-expansion-header-indicator-color: rgba(0, 0, 0, 0.54);
}

html {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

html {
  --mat-icon-color: inherit;
}

.mat-icon.mat-primary {
  --mat-icon-color: #e6007e;
}
.mat-icon.mat-accent {
  --mat-icon-color: #778186;
}
.mat-icon.mat-warn {
  --mat-icon-color: red;
}

html {
  --mat-sidenav-container-divider-color: rgba(0, 0, 0, 0.12);
  --mat-sidenav-container-background-color: white;
  --mat-sidenav-container-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-content-background-color: #fafafa;
  --mat-sidenav-content-text-color: rgba(0, 0, 0, 0.87);
  --mat-sidenav-scrim-color: rgba(0, 0, 0, 0.6);
}

html {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #e6007e;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #e6007e;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #e6007e;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: white;
  --mat-stepper-line-color: rgba(0, 0, 0, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(44, 44, 44, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(44, 44, 44, 0.04);
  --mat-stepper-header-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-optional-label-text-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-selected-state-label-text-color: rgba(0, 0, 0, 0.87);
  --mat-stepper-header-error-state-label-text-color: red;
  --mat-stepper-header-icon-background-color: rgba(0, 0, 0, 0.54);
  --mat-stepper-header-error-state-icon-foreground-color: red;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
html .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #778186;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #778186;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #778186;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
html .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: red;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: red;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: red;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

html {
  --mat-stepper-header-height: 72px;
}

.mat-sort-header-arrow {
  color: #757575;
}

html {
  --mat-toolbar-container-background-color: #ededed;
  --mat-toolbar-container-text-color: rgba(0, 0, 0, 0.87);
}

.mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #e6007e;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #778186;
  --mat-toolbar-container-text-color: white;
}
.mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: red;
  --mat-toolbar-container-text-color: white;
}

html {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

.mat-tree {
  background: white;
}

.mat-tree-node,
.mat-nested-tree-node {
  color: rgba(0, 0, 0, 0.87);
}

.mat-tree-node {
  min-height: 48px;
}

.sidenav-active {
  background-color: rgba(230, 0, 126, var(--mdc-list-list-item-focus-state-layer-opacity)) !important;
}
.sidenav-active:focus::before {
  background-color: transparent !important;
}
.sidenav-active .mdc-list-item__primary-text {
  color: #e6007e !important;
}

.theme-alternate {
  --mat-table-background-color: #393939;
  --mat-table-header-headline-color: white;
  --mat-table-row-item-label-text-color: white;
  --mat-table-row-item-outline-color: rgba(255, 255, 255, 0.12);
  --mat-table-header-container-height: 56px;
  --mat-table-footer-container-height: 52px;
  --mat-table-row-item-container-height: 52px;
}
.theme-alternate .mat-ripple-element {
  background-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate {
  --mat-option-selected-state-label-text-color: #e6007e;
  --mat-option-label-text-color: white;
  --mat-option-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-option-selected-state-layer-color: rgba(255, 255, 255, 0.08);
}

.theme-alternate .mat-accent {
  --mat-option-selected-state-label-text-color: #778186;
}
.theme-alternate .mat-warn {
  --mat-option-selected-state-label-text-color: red;
}
.theme-alternate {
  --mat-optgroup-label-text-color: white;
}

.theme-alternate .mat-pseudo-checkbox-full {
  color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-pseudo-checkbox-full.mat-pseudo-checkbox-disabled {
  color: #686868;
}
.theme-alternate .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.theme-alternate .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #e6007e;
}
.theme-alternate .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.theme-alternate .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #e6007e;
}
.theme-alternate .mat-primary .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.theme-alternate .mat-primary .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #2c2c2c;
}
.theme-alternate .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.theme-alternate .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #778186;
}
.theme-alternate .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.theme-alternate .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #778186;
}
.theme-alternate .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.theme-alternate .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #2c2c2c;
}
.theme-alternate .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.theme-alternate .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #778186;
}
.theme-alternate .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.theme-alternate .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #778186;
}
.theme-alternate .mat-accent .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.theme-alternate .mat-accent .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #2c2c2c;
}
.theme-alternate .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.theme-alternate .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: red;
}
.theme-alternate .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.theme-alternate .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: red;
}
.theme-alternate .mat-warn .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full::after,
.theme-alternate .mat-warn .mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full::after {
  color: #2c2c2c;
}
.theme-alternate .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after,
.theme-alternate .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-minimal::after {
  color: #686868;
}
.theme-alternate .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full,
.theme-alternate .mat-pseudo-checkbox-disabled.mat-pseudo-checkbox-indeterminate.mat-pseudo-checkbox-full {
  background: #686868;
}
.theme-alternate .mat-app-background, .theme-alternate.mat-app-background {
  background-color: #2c2c2c;
  color: white;
}
.theme-alternate .mat-elevation-z0, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z1, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z2, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z3, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z4, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z5, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z6, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z7, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z8, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z9, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z10, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z11, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z12, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z13, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z14, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z15, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z16, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z17, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z18, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z19, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z20, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z21, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z22, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z23, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12);
}
.theme-alternate .mat-elevation-z24, .theme-alternate .mat-mdc-elevation-specific.mat-elevation-z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
}
.mat-theme-loaded-marker {
  display: none;
}

.theme-alternate .mat-mdc-card {
  --mdc-elevated-card-container-color: #393939;
  --mdc-elevated-card-container-elevation: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-outlined-card-container-color: #393939;
  --mdc-outlined-card-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-card-container-elevation: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mat-card-subtitle-text-color: rgba(255, 255, 255, 0.7);
}
.theme-alternate .mat-mdc-progress-bar {
  --mdc-linear-progress-active-indicator-color: #e6007e;
  --mdc-linear-progress-track-color: rgba(230, 0, 126, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.theme-alternate .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
  background-color: rgba(230, 0, 126, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(230, 0, 126, 0.25));
}
@media (forced-colors: active) {
  .theme-alternate .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .theme-alternate .mat-mdc-progress-bar .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(230, 0, 126, 0.25)'/%3E%3C/svg%3E");
  }
}
.theme-alternate .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
  background-color: rgba(230, 0, 126, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(230, 0, 126, 0.25));
}
.theme-alternate .mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: #778186;
  --mdc-linear-progress-track-color: rgba(119, 129, 134, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.theme-alternate .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
  background-color: rgba(119, 129, 134, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(119, 129, 134, 0.25));
}
@media (forced-colors: active) {
  .theme-alternate .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .theme-alternate .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(119, 129, 134, 0.25)'/%3E%3C/svg%3E");
  }
}
.theme-alternate .mat-mdc-progress-bar.mat-accent .mdc-linear-progress__buffer-bar {
  background-color: rgba(119, 129, 134, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(119, 129, 134, 0.25));
}
.theme-alternate .mat-mdc-progress-bar.mat-warn {
  --mdc-linear-progress-active-indicator-color: red;
  --mdc-linear-progress-track-color: rgba(255, 0, 0, 0.25);
}
@keyframes mdc-linear-progress-buffering {
  from {
    /* @noflip */ /*rtl:ignore*/
  }
}
.theme-alternate .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
  background-color: rgba(255, 0, 0, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(255, 0, 0, 0.25));
}
@media (forced-colors: active) {
  .theme-alternate .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: ButtonBorder;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .theme-alternate .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-dots {
    background-color: transparent;
    background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='rgba(255, 0, 0, 0.25)'/%3E%3C/svg%3E");
  }
}
.theme-alternate .mat-mdc-progress-bar.mat-warn .mdc-linear-progress__buffer-bar {
  background-color: rgba(255, 0, 0, 0.25);
  /* @alternate */
  background-color: var(--mdc-linear-progress-track-color, rgba(255, 0, 0, 0.25));
}
.theme-alternate .mat-mdc-tooltip {
  --mdc-plain-tooltip-container-color: #5f5f5f;
  --mdc-plain-tooltip-supporting-text-color: #fff;
}
.theme-alternate {
  --mdc-filled-text-field-caret-color: #e6007e;
  --mdc-filled-text-field-focus-active-indicator-color: #e6007e;
  --mdc-filled-text-field-focus-label-text-color: rgba(230, 0, 126, 0.87);
  --mdc-filled-text-field-container-color: #414141;
  --mdc-filled-text-field-disabled-container-color: #3d3d3d;
  --mdc-filled-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-filled-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-filled-text-field-error-focus-label-text-color: red;
  --mdc-filled-text-field-error-label-text-color: red;
  --mdc-filled-text-field-error-caret-color: red;
  --mdc-filled-text-field-active-indicator-color: rgba(255, 255, 255, 0.42);
  --mdc-filled-text-field-disabled-active-indicator-color: rgba(255, 255, 255, 0.06);
  --mdc-filled-text-field-hover-active-indicator-color: rgba(255, 255, 255, 0.87);
  --mdc-filled-text-field-error-active-indicator-color: red;
  --mdc-filled-text-field-error-focus-active-indicator-color: red;
  --mdc-filled-text-field-error-hover-active-indicator-color: red;
  --mdc-outlined-text-field-caret-color: #e6007e;
  --mdc-outlined-text-field-focus-outline-color: #e6007e;
  --mdc-outlined-text-field-focus-label-text-color: rgba(230, 0, 126, 0.87);
  --mdc-outlined-text-field-label-text-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-disabled-label-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-disabled-input-text-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-input-text-placeholder-color: rgba(255, 255, 255, 0.6);
  --mdc-outlined-text-field-error-caret-color: red;
  --mdc-outlined-text-field-error-focus-label-text-color: red;
  --mdc-outlined-text-field-error-label-text-color: red;
  --mdc-outlined-text-field-outline-color: rgba(255, 255, 255, 0.38);
  --mdc-outlined-text-field-disabled-outline-color: rgba(255, 255, 255, 0.06);
  --mdc-outlined-text-field-hover-outline-color: rgba(255, 255, 255, 0.87);
  --mdc-outlined-text-field-error-focus-outline-color: red;
  --mdc-outlined-text-field-error-hover-outline-color: red;
  --mdc-outlined-text-field-error-outline-color: red;
  --mat-form-field-disabled-input-text-placeholder-color: rgba(255, 255, 255, 0.38);
}

.theme-alternate .mat-mdc-form-field-error {
  color: var(--mdc-theme-error, red);
}
.theme-alternate .mat-mdc-form-field-subscript-wrapper,
.theme-alternate .mat-mdc-form-field-bottom-align::before {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: var(--mat-form-field-subscript-text-font);
  line-height: var(--mat-form-field-subscript-text-line-height);
  font-size: var(--mat-form-field-subscript-text-size);
  letter-spacing: var(--mat-form-field-subscript-text-tracking);
  font-weight: var(--mat-form-field-subscript-text-weight);
}
.theme-alternate .mat-mdc-form-field-focus-overlay {
  background-color: rgba(255, 255, 255, 0.87);
}
.theme-alternate .mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay {
  opacity: 0.08;
}
.theme-alternate .mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.24;
}
.theme-alternate select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option {
  color: rgba(0, 0, 0, 0.87);
}
.theme-alternate select.mat-mdc-form-field-input-control:not(.mat-mdc-native-select-inline) option:disabled {
  color: rgba(0, 0, 0, 0.38);
}
.theme-alternate .mat-mdc-form-field-type-mat-native-select .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.54);
}
.theme-alternate .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-primary .mat-mdc-form-field-infix::after {
  color: rgba(230, 0, 126, 0.87);
}
.theme-alternate .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-accent .mat-mdc-form-field-infix::after {
  color: rgba(119, 129, 134, 0.87);
}
.theme-alternate .mat-mdc-form-field-type-mat-native-select.mat-focused.mat-warn .mat-mdc-form-field-infix::after {
  color: rgba(255, 0, 0, 0.87);
}
.theme-alternate .mat-mdc-form-field-type-mat-native-select.mat-form-field-disabled .mat-mdc-form-field-infix::after {
  color: rgba(255, 255, 255, 0.38);
}
.theme-alternate .mat-mdc-form-field.mat-accent {
  --mdc-filled-text-field-caret-color: #778186;
  --mdc-filled-text-field-focus-active-indicator-color: #778186;
  --mdc-filled-text-field-focus-label-text-color: rgba(119, 129, 134, 0.87);
  --mdc-outlined-text-field-caret-color: #778186;
  --mdc-outlined-text-field-focus-outline-color: #778186;
  --mdc-outlined-text-field-focus-label-text-color: rgba(119, 129, 134, 0.87);
}
.theme-alternate .mat-mdc-form-field.mat-warn {
  --mdc-filled-text-field-caret-color: red;
  --mdc-filled-text-field-focus-active-indicator-color: red;
  --mdc-filled-text-field-focus-label-text-color: rgba(255, 0, 0, 0.87);
  --mdc-outlined-text-field-caret-color: red;
  --mdc-outlined-text-field-focus-outline-color: red;
  --mdc-outlined-text-field-focus-label-text-color: rgba(255, 0, 0, 0.87);
}
.theme-alternate .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: 1px solid transparent;
}
.theme-alternate [dir=rtl] .mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field.mat-mdc-form-field .mdc-notched-outline__notch {
  border-left: none;
  border-right: 1px solid transparent;
}
.theme-alternate .mat-mdc-form-field-infix {
  min-height: 56px;
}
.theme-alternate .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 28px;
}
.theme-alternate .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -34.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.theme-alternate .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}
.theme-alternate .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 24px;
  padding-bottom: 8px;
}
.theme-alternate .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 16px;
  padding-bottom: 16px;
}
.theme-alternate {
  --mat-select-panel-background-color: #393939;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(230, 0, 126, 0.87);
  --mat-select-invalid-arrow-color: rgba(255, 0, 0, 0.87);
}
.theme-alternate .mat-mdc-form-field.mat-accent {
  --mat-select-panel-background-color: #393939;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(119, 129, 134, 0.87);
  --mat-select-invalid-arrow-color: rgba(255, 0, 0, 0.87);
}
.theme-alternate .mat-mdc-form-field.mat-warn {
  --mat-select-panel-background-color: #393939;
  --mat-select-enabled-trigger-text-color: rgba(255, 255, 255, 0.87);
  --mat-select-disabled-trigger-text-color: rgba(255, 255, 255, 0.38);
  --mat-select-placeholder-text-color: rgba(255, 255, 255, 0.6);
  --mat-select-enabled-arrow-color: rgba(255, 255, 255, 0.54);
  --mat-select-disabled-arrow-color: rgba(255, 255, 255, 0.38);
  --mat-select-focused-arrow-color: rgba(255, 0, 0, 0.87);
  --mat-select-invalid-arrow-color: rgba(255, 0, 0, 0.87);
}

.theme-alternate {
  --mat-autocomplete-background-color: #393939;
}

.theme-alternate .mat-mdc-dialog-container {
  --mdc-dialog-container-color: #393939;
  --mdc-dialog-subhead-color: rgba(255, 255, 255, 0.87);
  --mdc-dialog-supporting-text-color: rgba(255, 255, 255, 0.6);
}
.theme-alternate .mat-mdc-standard-chip {
  --mdc-chip-disabled-label-text-color: #fafafa;
  --mdc-chip-elevated-container-color: #515151;
  --mdc-chip-elevated-disabled-container-color: #515151;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: #fafafa;
  --mdc-chip-with-icon-icon-color: #fafafa;
  --mdc-chip-with-icon-disabled-icon-color: #fafafa;
  --mdc-chip-with-icon-selected-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: #fafafa;
  --mdc-chip-with-trailing-icon-trailing-icon-color: #fafafa;
}
.theme-alternate .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-primary, .theme-alternate .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-primary {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #e6007e;
  --mdc-chip-elevated-disabled-container-color: #e6007e;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.theme-alternate .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-accent, .theme-alternate .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-accent {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: #778186;
  --mdc-chip-elevated-disabled-container-color: #778186;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.theme-alternate .mat-mdc-standard-chip.mat-mdc-chip-selected.mat-warn, .theme-alternate .mat-mdc-standard-chip.mat-mdc-chip-highlighted.mat-warn {
  --mdc-chip-disabled-label-text-color: white;
  --mdc-chip-elevated-container-color: red;
  --mdc-chip-elevated-disabled-container-color: red;
  --mdc-chip-focus-state-layer-color: white;
  --mdc-chip-focus-state-layer-opacity: 0.12;
  --mdc-chip-label-text-color: white;
  --mdc-chip-with-icon-icon-color: white;
  --mdc-chip-with-icon-disabled-icon-color: white;
  --mdc-chip-with-icon-selected-icon-color: white;
  --mdc-chip-with-trailing-icon-disabled-trailing-icon-color: white;
  --mdc-chip-with-trailing-icon-trailing-icon-color: white;
}
.theme-alternate .mat-mdc-chip.mat-mdc-standard-chip {
  --mdc-chip-container-height: 32px;
}
.theme-alternate .mat-mdc-slide-toggle {
  --mdc-switch-selected-focus-handle-color: #e6007e;
  --mdc-switch-selected-hover-handle-color: #e6007e;
  --mdc-switch-selected-pressed-handle-color: #e6007e;
  --mdc-switch-disabled-selected-handle-color: #000;
  --mdc-switch-disabled-selected-icon-color: #212121;
  --mdc-switch-disabled-selected-track-color: #f5f5f5;
  --mdc-switch-disabled-unselected-handle-color: #000;
  --mdc-switch-disabled-unselected-icon-color: #212121;
  --mdc-switch-disabled-unselected-track-color: #f5f5f5;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-handle-elevation-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-handle-shadow-color: black;
  --mdc-switch-disabled-handle-elevation-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12);
  --mdc-switch-selected-icon-color: #212121;
  --mdc-switch-unselected-focus-handle-color: #fafafa;
  --mdc-switch-unselected-focus-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-focus-track-color: #616161;
  --mdc-switch-unselected-handle-color: #9e9e9e;
  --mdc-switch-unselected-hover-handle-color: #fafafa;
  --mdc-switch-unselected-hover-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-hover-track-color: #616161;
  --mdc-switch-unselected-icon-color: #212121;
  --mdc-switch-unselected-pressed-handle-color: #fafafa;
  --mdc-switch-unselected-pressed-state-layer-color: #f5f5f5;
  --mdc-switch-unselected-pressed-track-color: #616161;
  --mdc-switch-unselected-track-color: #616161;
}
.theme-alternate .mat-mdc-slide-toggle .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-alternate .mat-mdc-slide-toggle .mdc-switch--disabled + label {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-handle-color: #778186;
  --mdc-switch-selected-hover-handle-color: #778186;
  --mdc-switch-selected-pressed-handle-color: #778186;
}
.theme-alternate .mat-mdc-slide-toggle.mat-warn {
  --mdc-switch-selected-focus-handle-color: red;
  --mdc-switch-selected-hover-handle-color: red;
  --mdc-switch-selected-pressed-handle-color: red;
}
.theme-alternate .mat-mdc-slide-toggle {
  --mdc-switch-state-layer-size: 48px;
}
.theme-alternate .mat-mdc-radio-button .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-alternate .mat-mdc-radio-button.mat-primary {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #e6007e;
  --mdc-radio-selected-hover-icon-color: #e6007e;
  --mdc-radio-selected-icon-color: #e6007e;
  --mdc-radio-selected-pressed-icon-color: #e6007e;
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: #e6007e;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-mdc-radio-button.mat-accent {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #778186;
  --mdc-radio-selected-hover-icon-color: #778186;
  --mdc-radio-selected-icon-color: #778186;
  --mdc-radio-selected-pressed-icon-color: #778186;
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: #778186;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-mdc-radio-button.mat-warn {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: red;
  --mdc-radio-selected-hover-icon-color: red;
  --mdc-radio-selected-icon-color: red;
  --mdc-radio-selected-pressed-icon-color: red;
  --mat-radio-ripple-color: #fff;
  --mat-radio-checked-ripple-color: red;
  --mat-radio-disabled-label-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-mdc-radio-button .mdc-radio {
  --mdc-radio-state-layer-size: 40px;
}
.theme-alternate .mat-mdc-slider {
  --mdc-slider-label-container-color: white;
  --mdc-slider-label-label-text-color: black;
  --mdc-slider-disabled-handle-color: #fff;
  --mdc-slider-disabled-active-track-color: #fff;
  --mdc-slider-disabled-inactive-track-color: #fff;
  --mdc-slider-with-tick-marks-disabled-container-color: #fff;
  --mat-mdc-slider-value-indicator-opacity: 0.9;
}
.theme-alternate .mat-mdc-slider.mat-primary {
  --mdc-slider-handle-color: #e6007e;
  --mdc-slider-focus-handle-color: #e6007e;
  --mdc-slider-hover-handle-color: #e6007e;
  --mdc-slider-active-track-color: #e6007e;
  --mdc-slider-inactive-track-color: #e6007e;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #e6007e;
  --mat-mdc-slider-ripple-color: #e6007e;
  --mat-mdc-slider-hover-ripple-color: rgba(230, 0, 126, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(230, 0, 126, 0.2);
}
.theme-alternate .mat-mdc-slider.mat-accent {
  --mdc-slider-handle-color: #778186;
  --mdc-slider-focus-handle-color: #778186;
  --mdc-slider-hover-handle-color: #778186;
  --mdc-slider-active-track-color: #778186;
  --mdc-slider-inactive-track-color: #778186;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: #778186;
  --mat-mdc-slider-ripple-color: #778186;
  --mat-mdc-slider-hover-ripple-color: rgba(119, 129, 134, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(119, 129, 134, 0.2);
}
.theme-alternate .mat-mdc-slider.mat-warn {
  --mdc-slider-handle-color: red;
  --mdc-slider-focus-handle-color: red;
  --mdc-slider-hover-handle-color: red;
  --mdc-slider-active-track-color: red;
  --mdc-slider-inactive-track-color: red;
  --mdc-slider-with-tick-marks-active-container-color: #fff;
  --mdc-slider-with-tick-marks-inactive-container-color: red;
  --mat-mdc-slider-ripple-color: red;
  --mat-mdc-slider-hover-ripple-color: rgba(255, 0, 0, 0.05);
  --mat-mdc-slider-focus-ripple-color: rgba(255, 0, 0, 0.2);
}
.theme-alternate {
  --mat-menu-item-label-text-color: white;
  --mat-menu-item-icon-color: white;
  --mat-menu-item-hover-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-item-focus-state-layer-color: rgba(255, 255, 255, 0.08);
  --mat-menu-container-color: #393939;
}

.theme-alternate .mat-mdc-list-base {
  --mdc-list-list-item-label-text-color: white;
  --mdc-list-list-item-supporting-text-color: rgba(255, 255, 255, 0.7);
  --mdc-list-list-item-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-supporting-text-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-selected-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-disabled-label-text-color: white;
  --mdc-list-list-item-disabled-leading-icon-color: white;
  --mdc-list-list-item-disabled-trailing-icon-color: white;
  --mdc-list-list-item-hover-label-text-color: white;
  --mdc-list-list-item-hover-leading-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-hover-trailing-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-list-list-item-focus-label-text-color: white;
  --mdc-list-list-item-hover-state-layer-color: white;
  --mdc-list-list-item-hover-state-layer-opacity: 0.08;
  --mdc-list-list-item-focus-state-layer-color: white;
  --mdc-list-list-item-focus-state-layer-opacity: 0.24;
}
.theme-alternate .mdc-list-item__start,
.theme-alternate .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #e6007e;
  --mdc-radio-selected-hover-icon-color: #e6007e;
  --mdc-radio-selected-icon-color: #e6007e;
  --mdc-radio-selected-pressed-icon-color: #e6007e;
}
.theme-alternate .mat-accent .mdc-list-item__start,
.theme-alternate .mat-accent .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: #778186;
  --mdc-radio-selected-hover-icon-color: #778186;
  --mdc-radio-selected-icon-color: #778186;
  --mdc-radio-selected-pressed-icon-color: #778186;
}
.theme-alternate .mat-warn .mdc-list-item__start,
.theme-alternate .mat-warn .mdc-list-item__end {
  --mdc-radio-disabled-selected-icon-color: #fff;
  --mdc-radio-disabled-unselected-icon-color: #fff;
  --mdc-radio-unselected-hover-icon-color: #eeeeee;
  --mdc-radio-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-radio-selected-focus-icon-color: red;
  --mdc-radio-selected-hover-icon-color: red;
  --mdc-radio-selected-icon-color: red;
  --mdc-radio-selected-pressed-icon-color: red;
}
.theme-alternate .mat-mdc-list-option {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #e6007e;
  --mdc-checkbox-selected-hover-icon-color: #e6007e;
  --mdc-checkbox-selected-icon-color: #e6007e;
  --mdc-checkbox-selected-pressed-icon-color: #e6007e;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #e6007e;
  --mdc-checkbox-selected-hover-state-layer-color: #e6007e;
  --mdc-checkbox-selected-pressed-state-layer-color: #e6007e;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-alternate .mat-mdc-list-option.mat-accent {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #778186;
  --mdc-checkbox-selected-hover-icon-color: #778186;
  --mdc-checkbox-selected-icon-color: #778186;
  --mdc-checkbox-selected-pressed-icon-color: #778186;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #778186;
  --mdc-checkbox-selected-hover-state-layer-color: #778186;
  --mdc-checkbox-selected-pressed-state-layer-color: #778186;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-alternate .mat-mdc-list-option.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: red;
  --mdc-checkbox-selected-hover-icon-color: red;
  --mdc-checkbox-selected-icon-color: red;
  --mdc-checkbox-selected-pressed-icon-color: red;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: red;
  --mdc-checkbox-selected-hover-state-layer-color: red;
  --mdc-checkbox-selected-pressed-state-layer-color: red;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-alternate .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected .mdc-list-item__primary-text,
.theme-alternate .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated .mdc-list-item__primary-text {
  color: #e6007e;
}
.theme-alternate .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--selected.mdc-list-item--with-leading-icon .mdc-list-item__start,
.theme-alternate .mat-mdc-list-base.mat-mdc-list-base .mdc-list-item--activated.mdc-list-item--with-leading-icon .mdc-list-item__start {
  color: #e6007e;
}
.theme-alternate .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__start,
.theme-alternate .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__content,
.theme-alternate .mat-mdc-list-base .mdc-list-item--disabled .mdc-list-item__end {
  opacity: 1;
}
.theme-alternate .mat-mdc-list-base {
  --mdc-list-list-item-one-line-container-height: 48px;
  --mdc-list-list-item-two-line-container-height: 64px;
  --mdc-list-list-item-three-line-container-height: 88px;
}
.theme-alternate .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-one-line, .theme-alternate .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-one-line, .theme-alternate .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-one-line {
  height: 56px;
}
.theme-alternate .mat-mdc-list-item.mdc-list-item--with-leading-avatar.mdc-list-item--with-two-lines, .theme-alternate .mat-mdc-list-item.mdc-list-item--with-leading-checkbox.mdc-list-item--with-two-lines, .theme-alternate .mat-mdc-list-item.mdc-list-item--with-leading-icon.mdc-list-item--with-two-lines {
  height: 72px;
}
.theme-alternate {
  --mat-paginator-container-text-color: rgba(255, 255, 255, 0.87);
  --mat-paginator-container-background-color: #393939;
  --mat-paginator-enabled-icon-color: rgba(255, 255, 255, 0.54);
  --mat-paginator-disabled-icon-color: rgba(255, 255, 255, 0.12);
}

.theme-alternate {
  --mat-paginator-container-size: 56px;
}

.theme-alternate .mat-mdc-paginator .mat-mdc-form-field-infix {
  min-height: 40px;
}
.theme-alternate .mat-mdc-paginator .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: 20px;
}
.theme-alternate .mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
          -26.75px)
          scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  transform: var(--mat-mdc-form-field-label-transform);
}
.theme-alternate .mat-mdc-paginator .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.theme-alternate .mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.theme-alternate .mat-mdc-paginator .mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-top: 8px;
  padding-bottom: 8px;
}
.theme-alternate .mat-mdc-paginator .mat-mdc-text-field-wrapper:not(.mdc-text-field--outlined) .mat-mdc-floating-label {
  display: none;
}
.theme-alternate .mat-mdc-tab-group, .theme-alternate .mat-mdc-tab-nav-bar {
  --mdc-tab-indicator-active-indicator-color: #e6007e;
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #e6007e;
  --mat-tab-header-active-ripple-color: #e6007e;
  --mat-tab-header-inactive-ripple-color: #e6007e;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #e6007e;
  --mat-tab-header-active-hover-label-text-color: #e6007e;
  --mat-tab-header-active-focus-indicator-color: #e6007e;
  --mat-tab-header-active-hover-indicator-color: #e6007e;
}
.theme-alternate .mat-mdc-tab-group.mat-accent, .theme-alternate .mat-mdc-tab-nav-bar.mat-accent {
  --mdc-tab-indicator-active-indicator-color: #778186;
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: #778186;
  --mat-tab-header-active-ripple-color: #778186;
  --mat-tab-header-inactive-ripple-color: #778186;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: #778186;
  --mat-tab-header-active-hover-label-text-color: #778186;
  --mat-tab-header-active-focus-indicator-color: #778186;
  --mat-tab-header-active-hover-indicator-color: #778186;
}
.theme-alternate .mat-mdc-tab-group.mat-warn, .theme-alternate .mat-mdc-tab-nav-bar.mat-warn {
  --mdc-tab-indicator-active-indicator-color: red;
  --mat-tab-header-disabled-ripple-color: rgba(255, 255, 255, 0.5);
  --mat-tab-header-pagination-icon-color: #fff;
  --mat-tab-header-inactive-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-label-text-color: red;
  --mat-tab-header-active-ripple-color: red;
  --mat-tab-header-inactive-ripple-color: red;
  --mat-tab-header-inactive-focus-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-inactive-hover-label-text-color: rgba(255, 255, 255, 0.6);
  --mat-tab-header-active-focus-label-text-color: red;
  --mat-tab-header-active-hover-label-text-color: red;
  --mat-tab-header-active-focus-indicator-color: red;
  --mat-tab-header-active-hover-indicator-color: red;
}
.theme-alternate .mat-mdc-tab-group.mat-background-primary, .theme-alternate .mat-mdc-tab-nav-bar.mat-background-primary {
  --mat-tab-header-with-background-background-color: #e6007e;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-alternate .mat-mdc-tab-group.mat-background-accent, .theme-alternate .mat-mdc-tab-nav-bar.mat-background-accent {
  --mat-tab-header-with-background-background-color: #778186;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-alternate .mat-mdc-tab-group.mat-background-warn, .theme-alternate .mat-mdc-tab-nav-bar.mat-background-warn {
  --mat-tab-header-with-background-background-color: red;
  --mat-tab-header-with-background-foreground-color: white;
}
.theme-alternate .mat-mdc-tab-header {
  --mdc-secondary-navigation-tab-container-height: 48px;
}
.theme-alternate {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #778186;
  --mdc-checkbox-selected-hover-icon-color: #778186;
  --mdc-checkbox-selected-icon-color: #778186;
  --mdc-checkbox-selected-pressed-icon-color: #778186;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #778186;
  --mdc-checkbox-selected-hover-state-layer-color: #778186;
  --mdc-checkbox-selected-pressed-state-layer-color: #778186;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}

.theme-alternate .mat-mdc-checkbox.mat-primary {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: #e6007e;
  --mdc-checkbox-selected-hover-icon-color: #e6007e;
  --mdc-checkbox-selected-icon-color: #e6007e;
  --mdc-checkbox-selected-pressed-icon-color: #e6007e;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: #e6007e;
  --mdc-checkbox-selected-hover-state-layer-color: #e6007e;
  --mdc-checkbox-selected-pressed-state-layer-color: #e6007e;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-alternate .mat-mdc-checkbox.mat-warn {
  --mdc-checkbox-disabled-selected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-disabled-unselected-icon-color: rgba(255, 255, 255, 0.38);
  --mdc-checkbox-selected-checkmark-color: #fff;
  --mdc-checkbox-selected-focus-icon-color: red;
  --mdc-checkbox-selected-hover-icon-color: red;
  --mdc-checkbox-selected-icon-color: red;
  --mdc-checkbox-selected-pressed-icon-color: red;
  --mdc-checkbox-unselected-focus-icon-color: #eeeeee;
  --mdc-checkbox-unselected-hover-icon-color: #eeeeee;
  --mdc-checkbox-unselected-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-unselected-pressed-icon-color: rgba(255, 255, 255, 0.54);
  --mdc-checkbox-selected-focus-state-layer-color: red;
  --mdc-checkbox-selected-hover-state-layer-color: red;
  --mdc-checkbox-selected-pressed-state-layer-color: red;
  --mdc-checkbox-unselected-focus-state-layer-color: white;
  --mdc-checkbox-unselected-hover-state-layer-color: white;
  --mdc-checkbox-unselected-pressed-state-layer-color: white;
}
.theme-alternate .mat-mdc-checkbox .mdc-form-field {
  color: var(--mdc-theme-text-primary-on-background, white);
}
.theme-alternate .mat-mdc-checkbox.mat-mdc-checkbox-disabled label {
  color: rgba(255, 255, 255, 0.5);
}
.theme-alternate {
  --mdc-checkbox-state-layer-size: 40px;
}

.theme-alternate .mat-mdc-button.mat-unthemed {
  --mdc-text-button-label-text-color: #fff;
}
.theme-alternate .mat-mdc-button.mat-primary {
  --mdc-text-button-label-text-color: #e6007e;
}
.theme-alternate .mat-mdc-button.mat-accent {
  --mdc-text-button-label-text-color: #778186;
}
.theme-alternate .mat-mdc-button.mat-warn {
  --mdc-text-button-label-text-color: red;
}
.theme-alternate .mat-mdc-button[disabled][disabled] {
  --mdc-text-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-text-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-mdc-unelevated-button.mat-unthemed {
  --mdc-filled-button-container-color: #393939;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-alternate .mat-mdc-unelevated-button.mat-primary {
  --mdc-filled-button-container-color: #e6007e;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-alternate .mat-mdc-unelevated-button.mat-accent {
  --mdc-filled-button-container-color: #778186;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-alternate .mat-mdc-unelevated-button.mat-warn {
  --mdc-filled-button-container-color: red;
  --mdc-filled-button-label-text-color: #fff;
}
.theme-alternate .mat-mdc-unelevated-button[disabled][disabled] {
  --mdc-filled-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-filled-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-filled-button-label-text-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-mdc-raised-button.mat-unthemed {
  --mdc-protected-button-container-color: #393939;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-alternate .mat-mdc-raised-button.mat-primary {
  --mdc-protected-button-container-color: #e6007e;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-alternate .mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: #778186;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-alternate .mat-mdc-raised-button.mat-warn {
  --mdc-protected-button-container-color: red;
  --mdc-protected-button-label-text-color: #fff;
}
.theme-alternate .mat-mdc-raised-button[disabled][disabled] {
  --mdc-protected-button-disabled-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-color: rgba(255, 255, 255, 0.12);
  --mdc-protected-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-protected-button-container-elevation: 0;
}
.theme-alternate .mat-mdc-outlined-button {
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-mdc-outlined-button.mat-unthemed {
  --mdc-outlined-button-label-text-color: #fff;
}
.theme-alternate .mat-mdc-outlined-button.mat-primary {
  --mdc-outlined-button-label-text-color: #e6007e;
}
.theme-alternate .mat-mdc-outlined-button.mat-accent {
  --mdc-outlined-button-label-text-color: #778186;
}
.theme-alternate .mat-mdc-outlined-button.mat-warn {
  --mdc-outlined-button-label-text-color: red;
}
.theme-alternate .mat-mdc-outlined-button[disabled][disabled] {
  --mdc-outlined-button-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-disabled-label-text-color: rgba(255, 255, 255, 0.5);
  --mdc-outlined-button-outline-color: rgba(255, 255, 255, 0.12);
  --mdc-outlined-button-disabled-outline-color: rgba(255, 255, 255, 0.12);
}
.theme-alternate .mat-mdc-button, .theme-alternate .mat-mdc-outlined-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-mdc-button:hover .mat-mdc-button-persistent-ripple::before, .theme-alternate .mat-mdc-outlined-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.theme-alternate .mat-mdc-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-alternate .mat-mdc-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-alternate .mat-mdc-outlined-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-alternate .mat-mdc-outlined-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-alternate .mat-mdc-button:active .mat-mdc-button-persistent-ripple::before, .theme-alternate .mat-mdc-outlined-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-alternate .mat-mdc-button.mat-primary, .theme-alternate .mat-mdc-outlined-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #e6007e;
  --mat-mdc-button-ripple-color: rgba(230, 0, 126, 0.1);
}
.theme-alternate .mat-mdc-button.mat-accent, .theme-alternate .mat-mdc-outlined-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #778186;
  --mat-mdc-button-ripple-color: rgba(119, 129, 134, 0.1);
}
.theme-alternate .mat-mdc-button.mat-warn, .theme-alternate .mat-mdc-outlined-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: red;
  --mat-mdc-button-ripple-color: rgba(255, 0, 0, 0.1);
}
.theme-alternate .mat-mdc-raised-button, .theme-alternate .mat-mdc-unelevated-button {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-mdc-raised-button:hover .mat-mdc-button-persistent-ripple::before, .theme-alternate .mat-mdc-unelevated-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.theme-alternate .mat-mdc-raised-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-alternate .mat-mdc-raised-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before, .theme-alternate .mat-mdc-unelevated-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-alternate .mat-mdc-unelevated-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-alternate .mat-mdc-raised-button:active .mat-mdc-button-persistent-ripple::before, .theme-alternate .mat-mdc-unelevated-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-alternate .mat-mdc-raised-button.mat-primary, .theme-alternate .mat-mdc-unelevated-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-mdc-raised-button.mat-accent, .theme-alternate .mat-mdc-unelevated-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-mdc-raised-button.mat-warn, .theme-alternate .mat-mdc-unelevated-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-mdc-button.mat-mdc-button-base,
.theme-alternate .mat-mdc-raised-button.mat-mdc-button-base,
.theme-alternate .mat-mdc-unelevated-button.mat-mdc-button-base,
.theme-alternate .mat-mdc-outlined-button.mat-mdc-button-base {
  height: 36px;
}
.theme-alternate .mat-mdc-icon-button {
  --mat-mdc-button-persistent-ripple-color: #000;
  --mat-mdc-button-ripple-color: rgba(0, 0, 0, 0.1);
  --mdc-icon-button-icon-color: inherit;
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-mdc-icon-button:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.theme-alternate .mat-mdc-icon-button.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-alternate .mat-mdc-icon-button.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-alternate .mat-mdc-icon-button:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-alternate .mat-mdc-icon-button.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #6200ee;
  --mat-mdc-button-ripple-color: rgba(98, 0, 238, 0.1);
}
.theme-alternate .mat-mdc-icon-button.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #018786;
  --mat-mdc-button-ripple-color: rgba(1, 135, 134, 0.1);
}
.theme-alternate .mat-mdc-icon-button.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #b00020;
  --mat-mdc-button-ripple-color: rgba(176, 0, 32, 0.1);
}
.theme-alternate .mat-mdc-icon-button.mat-primary {
  --mdc-icon-button-icon-color: #e6007e;
  --mat-mdc-button-persistent-ripple-color: #e6007e;
  --mat-mdc-button-ripple-color: rgba(230, 0, 126, 0.1);
}
.theme-alternate .mat-mdc-icon-button.mat-accent {
  --mdc-icon-button-icon-color: #778186;
  --mat-mdc-button-persistent-ripple-color: #778186;
  --mat-mdc-button-ripple-color: rgba(119, 129, 134, 0.1);
}
.theme-alternate .mat-mdc-icon-button.mat-warn {
  --mdc-icon-button-icon-color: red;
  --mat-mdc-button-persistent-ripple-color: red;
  --mat-mdc-button-ripple-color: rgba(255, 0, 0, 0.1);
}
.theme-alternate .mat-mdc-icon-button[disabled][disabled] {
  --mdc-icon-button-icon-color: rgba(255, 255, 255, 0.5);
  --mdc-icon-button-disabled-icon-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 48px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 12px;
}
.theme-alternate .mat-mdc-fab,
.theme-alternate .mat-mdc-mini-fab {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-mdc-fab:hover .mat-mdc-button-persistent-ripple::before,
.theme-alternate .mat-mdc-mini-fab:hover .mat-mdc-button-persistent-ripple::before {
  opacity: 0.08;
}
.theme-alternate .mat-mdc-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before, .theme-alternate .mat-mdc-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before,
.theme-alternate .mat-mdc-mini-fab.cdk-program-focused .mat-mdc-button-persistent-ripple::before,
.theme-alternate .mat-mdc-mini-fab.cdk-keyboard-focused .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-alternate .mat-mdc-fab:active .mat-mdc-button-persistent-ripple::before,
.theme-alternate .mat-mdc-mini-fab:active .mat-mdc-button-persistent-ripple::before {
  opacity: 0.24;
}
.theme-alternate .mat-mdc-fab.mat-primary,
.theme-alternate .mat-mdc-mini-fab.mat-primary {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-mdc-fab.mat-accent,
.theme-alternate .mat-mdc-mini-fab.mat-accent {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-mdc-fab.mat-warn,
.theme-alternate .mat-mdc-mini-fab.mat-warn {
  --mat-mdc-button-persistent-ripple-color: #fff;
  --mat-mdc-button-ripple-color: rgba(255, 255, 255, 0.1);
}
.theme-alternate .mat-mdc-fab[disabled][disabled],
.theme-alternate .mat-mdc-mini-fab[disabled][disabled] {
  --mdc-fab-container-color: rgba(255, 255, 255, 0.12);
  --mdc-fab-icon-color: rgba(255, 255, 255, 0.5);
  --mat-mdc-fab-color: rgba(255, 255, 255, 0.5);
}
.theme-alternate .mat-mdc-fab.mat-unthemed,
.theme-alternate .mat-mdc-mini-fab.mat-unthemed {
  --mdc-fab-container-color: #393939;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.theme-alternate .mat-mdc-fab.mat-primary,
.theme-alternate .mat-mdc-mini-fab.mat-primary {
  --mdc-fab-container-color: #e6007e;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.theme-alternate .mat-mdc-fab.mat-accent,
.theme-alternate .mat-mdc-mini-fab.mat-accent {
  --mdc-fab-container-color: #778186;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.theme-alternate .mat-mdc-fab.mat-warn,
.theme-alternate .mat-mdc-mini-fab.mat-warn {
  --mdc-fab-container-color: red;
  --mdc-fab-icon-color: white;
  --mat-mdc-fab-color: #fff;
}
.theme-alternate .mat-mdc-snack-bar-container {
  --mdc-snackbar-container-color: #d7d7d7;
  --mdc-snackbar-supporting-text-color: rgba(57, 57, 57, 0.87);
  --mat-snack-bar-button-color: rgba(0, 0, 0, 0.87);
}
.theme-alternate .mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #e6007e;
}
.theme-alternate .mat-mdc-progress-spinner.mat-accent {
  --mdc-circular-progress-active-indicator-color: #778186;
}
.theme-alternate .mat-mdc-progress-spinner.mat-warn {
  --mdc-circular-progress-active-indicator-color: red;
}
.theme-alternate {
  --mat-badge-background-color: #e6007e;
  --mat-badge-text-color: white;
  --mat-badge-disabled-state-background-color: #6b6b6b;
  --mat-badge-disabled-state-text-color: rgba(255, 255, 255, 0.5);
}

.theme-alternate .mat-badge-accent {
  --mat-badge-background-color: #778186;
  --mat-badge-text-color: white;
}
.theme-alternate .mat-badge-warn {
  --mat-badge-background-color: red;
  --mat-badge-text-color: white;
}
.theme-alternate {
  --mat-bottom-sheet-container-text-color: white;
  --mat-bottom-sheet-container-background-color: #393939;
}

.theme-alternate {
  --mat-legacy-button-toggle-text-color: rgba(255, 255, 255, 0.5);
  --mat-legacy-button-toggle-state-layer-color: rgba(255, 255, 255, 0.12);
  --mat-legacy-button-toggle-selected-state-text-color: rgba(255, 255, 255, 0.7);
  --mat-legacy-button-toggle-selected-state-background-color: #5f5f5f;
  --mat-legacy-button-toggle-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-legacy-button-toggle-disabled-state-background-color: #464646;
  --mat-legacy-button-toggle-disabled-selected-state-background-color: #797979;
  --mat-standard-button-toggle-text-color: white;
  --mat-standard-button-toggle-background-color: #393939;
  --mat-standard-button-toggle-state-layer-color: white;
  --mat-standard-button-toggle-selected-state-background-color: #5f5f5f;
  --mat-standard-button-toggle-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-standard-button-toggle-disabled-state-background-color: #393939;
  --mat-standard-button-toggle-disabled-selected-state-text-color: white;
  --mat-standard-button-toggle-disabled-selected-state-background-color: #797979;
  --mat-standard-button-toggle-divider-color: #515151;
}

.theme-alternate {
  --mat-standard-button-toggle-height: 48px;
}

.theme-alternate {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #e6007e;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(230, 0, 126, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(230, 0, 126, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(230, 0, 126, 0.3);
  --mat-datepicker-toggle-active-state-icon-color: #e6007e;
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(230, 0, 126, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
  --mat-datepicker-toggle-icon-color: white;
  --mat-datepicker-calendar-body-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-datepicker-calendar-period-button-icon-color: white;
  --mat-datepicker-calendar-navigation-button-icon-color: white;
  --mat-datepicker-calendar-header-divider-color: rgba(255, 255, 255, 0.12);
  --mat-datepicker-calendar-header-text-color: rgba(255, 255, 255, 0.7);
  --mat-datepicker-calendar-date-today-outline-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-date-today-disabled-state-outline-color: rgba(255, 255, 255, 0.3);
  --mat-datepicker-calendar-date-text-color: white;
  --mat-datepicker-calendar-date-outline-color: transparent;
  --mat-datepicker-calendar-date-disabled-state-text-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-date-preview-state-outline-color: rgba(255, 255, 255, 0.24);
  --mat-datepicker-range-input-separator-color: white;
  --mat-datepicker-range-input-disabled-state-separator-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-range-input-disabled-state-text-color: rgba(255, 255, 255, 0.5);
  --mat-datepicker-calendar-container-background-color: #393939;
  --mat-datepicker-calendar-container-text-color: white;
}

.theme-alternate .mat-datepicker-content.mat-accent {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: #778186;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(119, 129, 134, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(119, 129, 134, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(119, 129, 134, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(119, 129, 134, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-alternate .mat-datepicker-content.mat-warn {
  --mat-datepicker-calendar-date-selected-state-text-color: white;
  --mat-datepicker-calendar-date-selected-state-background-color: red;
  --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(255, 0, 0, 0.4);
  --mat-datepicker-calendar-date-today-selected-state-outline-color: white;
  --mat-datepicker-calendar-date-focus-state-background-color: rgba(255, 0, 0, 0.3);
  --mat-datepicker-calendar-date-hover-state-background-color: rgba(255, 0, 0, 0.3);
  --mat-datepicker-calendar-date-in-range-state-background-color: rgba(255, 0, 0, 0.2);
  --mat-datepicker-calendar-date-in-comparison-range-state-background-color: rgba(249, 171, 0, 0.2);
  --mat-datepicker-calendar-date-in-overlap-range-state-background-color: #a8dab5;
  --mat-datepicker-calendar-date-in-overlap-range-selected-state-background-color: #46a35e;
}
.theme-alternate .mat-datepicker-toggle-active.mat-accent {
  --mat-datepicker-toggle-active-state-icon-color: #778186;
}
.theme-alternate .mat-datepicker-toggle-active.mat-warn {
  --mat-datepicker-toggle-active-state-icon-color: red;
}
.theme-alternate .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base {
  --mdc-icon-button-state-layer-size: 40px;
  width: var(--mdc-icon-button-state-layer-size);
  height: var(--mdc-icon-button-state-layer-size);
  padding: 8px;
}
.theme-alternate .mat-calendar-controls .mat-mdc-icon-button.mat-mdc-button-base .mat-mdc-button-touch-target {
  display: none;
}
.theme-alternate {
  --mat-divider-color: rgba(255, 255, 255, 0.12);
}

.theme-alternate {
  --mat-expansion-container-background-color: #393939;
  --mat-expansion-container-text-color: white;
  --mat-expansion-actions-divider-color: rgba(255, 255, 255, 0.12);
  --mat-expansion-header-hover-state-layer-color: rgba(250, 250, 250, 0.04);
  --mat-expansion-header-focus-state-layer-color: rgba(250, 250, 250, 0.04);
  --mat-expansion-header-disabled-state-text-color: rgba(255, 255, 255, 0.3);
  --mat-expansion-header-text-color: white;
  --mat-expansion-header-description-color: rgba(255, 255, 255, 0.7);
  --mat-expansion-header-indicator-color: rgba(255, 255, 255, 0.7);
}

.theme-alternate {
  --mat-expansion-header-collapsed-state-height: 48px;
  --mat-expansion-header-expanded-state-height: 64px;
}

.theme-alternate {
  --mat-icon-color: inherit;
}

.theme-alternate .mat-icon.mat-primary {
  --mat-icon-color: #e6007e;
}
.theme-alternate .mat-icon.mat-accent {
  --mat-icon-color: #778186;
}
.theme-alternate .mat-icon.mat-warn {
  --mat-icon-color: red;
}
.theme-alternate {
  --mat-sidenav-container-divider-color: rgba(255, 255, 255, 0.12);
  --mat-sidenav-container-background-color: #393939;
  --mat-sidenav-container-text-color: white;
  --mat-sidenav-content-background-color: #2c2c2c;
  --mat-sidenav-content-text-color: white;
  --mat-sidenav-scrim-color: rgba(198, 198, 198, 0.6);
}

.theme-alternate {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #e6007e;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #e6007e;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #e6007e;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
  --mat-stepper-container-color: #393939;
  --mat-stepper-line-color: rgba(255, 255, 255, 0.12);
  --mat-stepper-header-hover-state-layer-color: rgba(250, 250, 250, 0.04);
  --mat-stepper-header-focus-state-layer-color: rgba(250, 250, 250, 0.04);
  --mat-stepper-header-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-optional-label-text-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-selected-state-label-text-color: white;
  --mat-stepper-header-error-state-label-text-color: red;
  --mat-stepper-header-icon-background-color: rgba(255, 255, 255, 0.7);
  --mat-stepper-header-error-state-icon-foreground-color: red;
  --mat-stepper-header-error-state-icon-background-color: transparent;
}
.theme-alternate .mat-step-header.mat-accent {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: #778186;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: #778186;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: #778186;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}
.theme-alternate .mat-step-header.mat-warn {
  --mat-stepper-header-icon-foreground-color: white;
  --mat-stepper-header-selected-state-icon-background-color: red;
  --mat-stepper-header-selected-state-icon-foreground-color: white;
  --mat-stepper-header-done-state-icon-background-color: red;
  --mat-stepper-header-done-state-icon-foreground-color: white;
  --mat-stepper-header-edit-state-icon-background-color: red;
  --mat-stepper-header-edit-state-icon-foreground-color: white;
}

.theme-alternate {
  --mat-stepper-header-height: 72px;
}

.theme-alternate .mat-sort-header-arrow {
  color: #c4c4c4;
}
.theme-alternate {
  --mat-toolbar-container-background-color: #393939;
  --mat-toolbar-container-text-color: white;
}

.theme-alternate .mat-toolbar.mat-primary {
  --mat-toolbar-container-background-color: #e6007e;
  --mat-toolbar-container-text-color: white;
}
.theme-alternate .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: #778186;
  --mat-toolbar-container-text-color: white;
}
.theme-alternate .mat-toolbar.mat-warn {
  --mat-toolbar-container-background-color: red;
  --mat-toolbar-container-text-color: white;
}
.theme-alternate {
  --mat-toolbar-standard-height: 64px;
  --mat-toolbar-mobile-height: 56px;
}

.theme-alternate .mat-tree {
  background: #393939;
}
.theme-alternate .mat-tree-node,
.theme-alternate .mat-nested-tree-node {
  color: white;
}
.theme-alternate .sidenav-active {
  background-color: rgba(230, 0, 126, var(--mdc-list-list-item-focus-state-layer-opacity)) !important;
}
.theme-alternate .sidenav-active:focus::before {
  background-color: transparent !important;
}
.theme-alternate .sidenav-active .mdc-list-item__primary-text {
  color: #e6007e !important;
}

.mat-raised-button, .mat-stroked-button, .mat-flat-button {
  padding: 0 1.15em;
  margin: 0 0.65em;
  min-width: 3em;
  line-height: 36.4px;
}

.mat-standard-chip {
  padding: 0.5em 0.85em;
  min-height: 2.5em;
}

.material-icons {
  font-size: 24px;
  font-family: "Material Icons", "Material Icons";
}
.material-icons .mat-badge-content {
  font-family: "Open Sans";
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -ms-overflow-style: scrollbar;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  height: 100%;
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  margin: 0;
}

*,
*::before,
*::after {
  font-family: "Open Sans", sans-serif;
}

body {
  margin: 0;
  text-align: left;
  width: 100%;
  text-rendering: optimizeLegibility;
  font-feature-settings: "kern";
  -webkit-font-feature-settings: "kern";
}

/* total width */
::-webkit-scrollbar {
  background-color: #fff;
  width: 16px;
}

::-webkit-scrollbar-track {
  background-color: #fff;
}

::-webkit-scrollbar-thumb {
  background-color: #babac0;
  border-radius: 16px;
  border: 4px solid #fff;
}

::-webkit-scrollbar-button {
  display: none;
}

.mat-sidenav-content > *:not(router-outlet) {
  padding: 50px 20px 50px 20px;
  display: block;
}
@media (min-width: 1280px) {
  .mat-sidenav-content > *:not(router-outlet) {
    max-width: 1024px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media (min-width: 1536px) {
  .mat-sidenav-content > *:not(router-outlet) {
    max-width: 1280px;
  }
}
@media (min-width: 1920px) {
  .mat-sidenav-content > *:not(router-outlet) {
    max-width: 1536px;
  }
}

button:not(:last-child), a:not(:last-child) {
  margin-right: 12px;
}